import { useCallback, useMemo } from "react";
import { store } from "../store";
import { showErrorAlert } from "../store/actions/alerts";
import { useFreshUserData } from "./apiHooks/useFreshUserData";
import useUserData from "./useUserData";

export type TRoles =
  | "admin"
  | "ventura-staff"
  | "special-agent"
  | "agent"
  | "manager";

export interface IRole {
  label: string;
  value: TRoles;
  permissions: string[];
}

export interface IRoleMapping {
  [key: string]: IRole;
}

/** Returns all the permissions relating to showing specific price columns for products */
export const useCashColumnPermissions = (): string[] => {
  return [
    "see-retail-price",
    "see-cash-price",
    "see-best-cash-price",
    "see-your-price",
    "see-wholesale-price",
  ];
};

/** Returns an array of role objects containing the role metadata and the permissions */
export const useRoles = (): IRole[] => {
  let cashColumns = useCashColumnPermissions();

  const roles: IRole[] = [
    {
      label: "Admin",
      value: "admin",
      permissions: [
        "create-product",
        "edit-product",
        "delete-product",
        "create-user",
        "edit-user",
        "delete-user",
        "create-category",
        "edit-category",
        "delete-category",
        ...cashColumns,
      ],
    },
    {
      label: "Ventura Staff",
      value: "ventura-staff",
      permissions: [cashColumns[0], cashColumns[1], cashColumns[2]],
    },
    {
      label: "Special Agent",
      value: "special-agent",
      permissions: [
        cashColumns[0],
        cashColumns[1],
        cashColumns[2],
        cashColumns[3],
      ],
    },
    {
      label: "Agent",
      value: "agent",
      permissions: [cashColumns[0], cashColumns[1], cashColumns[4]],
    },
    {
      label: "Manager",
      value: "manager",
      permissions: [...cashColumns],
    },
  ];

  return roles;
};

/** Fetches a mapping object of all the roles with the keys being role values */
export const useRolesMapping = (): IRoleMapping => {
  const roles = useRoles();

  const roleMapping: IRoleMapping = {};

  roles.forEach((role) => (roleMapping[role.value] = role));

  return roleMapping;
};

/** Fetch the permissions for a specific role */
export const useRolePermissions = (role: string): string[] => {
  // console.log("Checking user role permissions for : ", role);
  const rolesMapping = useRolesMapping();

  return useMemo(
    () => (rolesMapping[role] ? rolesMapping[role].permissions : []),
    [role]
  );
};

/** Fetch active user permissions */
export const useActiveUserPermissions = (): string[] => {
  const result = useUserData();
  // const { result } = useFreshUserData();

  // console.log(result?.role)
  return useRolePermissions(result?.role ?? "");
};

/** Returns a method that helps you check to see if a user has a specific permission */
export const usePermissionChecker = () => {
  const permissions = useActiveUserPermissions();

  // stringified the arrays, in order to prevent react's triggers for new arrays with the same values
  const permissionsStringified = JSON.stringify(permissions);

  return useCallback(
    (role: string, showAlert: boolean = false): boolean => {
      const hasPermission: boolean = !!permissions.includes(role);

      if (!hasPermission && showAlert)
        store.dispatch(
          showErrorAlert({
            message: `You don't have the ${role} permission.`,
          })
        );

      return hasPermission;
    },
    [permissionsStringified]
  );
};
