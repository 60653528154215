import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import placeholderPic from "../../../assets/no_data_x.gif";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import GenericTable from "../../../components/GenericTable/GenericTable";
import users from "../../../configs/users";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import UserService from "../../../services/Entities/UserService";
import { usePermissionChecker } from "../../../hooks/useRoles";

const UsersTableView = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const session = useStoreSessionSelector();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedRows, setSelectedRows] = useState([]);
  const history = useHistory();
  const usersClient = new UserService();

  const permissionChecker = usePermissionChecker();

  let [numberOfData, setNdata] = useState<number>(0);

  const handleSearch = (value: string) => {
    if (value && value !== "") {
      setLoading(true);

      usersClient
        .getAllResources({ query: { query_all: value } })
        .then((response) => setList(response.data.data))
        .finally(() => setLoading(false));
    } else {
      fetchData();
    }
  };

  const fetchData = () => {
    setLoading(true);
    const payload = {
      token: session.token,
      query: {
        page: page,
        pageSize: rowsPerPage,
      },
    };
    usersClient
      .getAllResources(payload)
      .then((response) => {
        setNdata(response.data.total);
        return setList(response.data.data);
      })
      .finally(() => setLoading(false));
  };

  const deleteUser = () => {
    if (!permissionChecker("delete-user", true)) return;

    setLoading(true);

    usersClient
      .deleteManyResource({ token: session.token, data: selectedRows })
      .then(() => fetchData())
      .finally(() => setLoading(false));
  };

  React.useEffect(fetchData, [page, rowsPerPage]);

  const navigateToAddPage = () => {
    history.push(`users/add`);
  };

  const rowTriggerAction = (row: any) => {
    history.push(`users/${row?._id}`);
  };

  const handlePageChange = (e: any, value: any) => {
    setPage(value);
  };

  const handleRowsChange = (e: { target: { value: string } }) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  };

  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        onSearch={handleSearch}
        addResourceHandler={navigateToAddPage}
        deleteResourceHandler={deleteUser}
        selectedRows={selectedRows}
        model="user"
      />
      <div className="p-4">
        <h2 className="mb-4">Users</h2>
        <GenericTable
          list={list}
          totalCount={list.length}
          onSelectedChange={handleRowSelectionChange}
          selectedRows={selectedRows}
          page={page}
          numberOfData={numberOfData}
          // @ts-ignore
          loading={loading}
          rowsPerPage={rowsPerPage}
          onRowsChange={handleRowsChange}
          onPageChange={handlePageChange}
          image={placeholderPic}
          tableConfig={users}
          model="user"
          passRow={rowTriggerAction}
        />
      </div>
    </Box>
  );
};

export default UsersTableView;
