import { createContext, useContext } from "react";
import { IShoppingCartContext, IShoppingCartProduct } from "./types";

export const createShoppingCartContext = ({
  rows = [],
  setRows = (_rows) => {},
  tax = 0,
  setTax = (_tax) => {},
  discount = 0,
  setDiscount = (_discount) => {},
  shipping = 0,
  setShipping = (_shipping) => {},
  getBestTotal = () => {
    return rows?.reduce(
      (acc, r) => acc + (isNaN(r.bestPrice!) ? 0 : r.bestPrice!),
      0
    );
  },
  getRetailTotal = () => {
    return rows?.reduce(
      (acc, r) => acc + (isNaN(r.retailPrice!) ? 0 : r.retailPrice!),
      0
    );
  },
  reset = () => {
    setRows([]);
    setTax(undefined);
    setDiscount(undefined);
    setShipping(undefined);
  },
  openCart = () => {},
  addToCart = (ps: Partial<IShoppingCartProduct>[]) => {
    setRows(
      rows.concat(
        ps.map((p) => ({
          _id: Date.now().toString(16),
          quantity: 1,
          ...p,
        }))
      )
    );
  },
}: Partial<IShoppingCartContext> = {}): IShoppingCartContext => {
  return {
    rows,
    setRows,
    tax,
    setTax,
    discount,
    setDiscount,
    shipping,
    setShipping,
    getBestTotal,
    getRetailTotal,
    reset,
    openCart,
    addToCart,
  };
};

export const ShoppingCartContext = createContext<IShoppingCartContext>(
  createShoppingCartContext(createShoppingCartContext())
);

export const useShoppingCart = () => useContext(ShoppingCartContext);
