import React from "react";
import "./GoBackButton.scss";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import Box from "@mui/material/Box";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Button from "@mui/material/Button";

const GoBackButton = () => {
  const history = useHistory();
  const goBack = () => {
    // @ts-ignore
    history.goBack();
  };
  return (
    <Button
      className="px-3 py-1"
      onClick={goBack}
      variant="contained"
      startIcon={
        <FontAwesomeIcon
          icon={faChevronLeft as IconProp}
          color="#fff"
          className="icon"
        />
      }
    >
      <Box sx={{ display: { md: "initial", xs: "none" } }}>Go Back</Box>
    </Button>
  );
};

export default GoBackButton;
