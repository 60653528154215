import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faHamburger } from "@fortawesome/free-solid-svg-icons/faHamburger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import avantLogo from "../../assets/avant-logo-3-white_SVG.svg";
import user from "../../assets/user-placeholder.png";
import { useFreshUserData } from "../../hooks/apiHooks/useFreshUserData";
import useScreenSize from "../../hooks/useScreenSize";
import useUserData from "../../hooks/useUserData";
import { SidebarItem } from "../../interfaces/SidebarItem";
import { User } from "../../interfaces/Users";
import { logout } from "../../store/actions/session";
import GenericModal from "../Modal/Modal";
import Enable2FA from "../PageComponents/Authentication/Enable2FA";
import "./Sidebar.scss";
import { SKIP, useApiHook } from "providers/ApiProvider";
import useStoreSessionSelector from "hooks/useStoreSessionSelector";
const Sidebar = ({ isSidebarCollapsed }: any) => {
  const dispatch = useDispatch();
  const [activeRoute, setActiveRoute] = React.useState<string>(
    window.location.pathname.split("/")[1]
  );
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [modal2fa, setModal2fa] = React.useState<boolean>(false);

  // const activeUser: User = useUserData();
  // const { loading: loadingActiveUser, result: activeUser, error } = useFreshUserData();
  const session = useStoreSessionSelector();
  const userResponse = useApiHook(
    "Authentication",
    "getSpecificResource",
    session?.token ? "me" : SKIP,
    {}
  );

  const activeUser = userResponse?.data?.result;
  const loadingActiveUser = userResponse.loading;

  const [routes, setRoutes] = React.useState<any>([]);

  const regularRoutes = [
    {
      path: "/categories",
      label: "Categories",
      routeActiveHandler: "categories",
      icon: faHamburger as IconProp,
    },
    {
      path: "/productPrices/categories",
      label: "Product Prices",
      routeActiveHandler: "productPrices/categories",
      icon: faDollarSign as IconProp,
    },
  ];

  const adminRoutes = [
    {
      path: "/users",
      label: "Users",
      routeActiveHandler: "users",
      icon: faUser as IconProp,
    },
    ...regularRoutes,
  ];

  React.useEffect(() => {
    // console.log("Routes role useEffect: ", activeUser?.role);
    activeUser?.role === "admin"
      ? setRoutes([...adminRoutes])
      : setRoutes([...regularRoutes]);
  }, [activeUser]);

  const device = useScreenSize();
  const history = useHistory();

  const logUserOut = (): void => {
    dispatch(logout());
    history.push("/login");
  };

  const close2faModal = (): void => {
    setModal2fa(false);
  };

  return (
    <Box
      className={"sidebar " + (collapsed ? "" : "full")}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <GenericModal size="small" state={modal2fa} onClose={close2faModal}>
        <Enable2FA />
        <div className="d-flex justify-content-center mt-3">
          <button className="btn btn-secondary" onClick={close2faModal}>
            Close
          </button>
        </div>
      </GenericModal>

      <img className="logo" src="/LogoWhite.svg" />
      <Box
        className="sidebarAction"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <FontAwesomeIcon
          size="3x"
          icon={(collapsed ? faAngleRight : faAngleLeft) as IconProp}
          style={{ margin: "15px", cursor: "pointer" }}
          className="icon"
          onClick={() => {
            setCollapsed(!collapsed);
            isSidebarCollapsed(!collapsed);
          }}
        />
      </Box>
      <Box className="topBox">
        {device !== "tablet" && !collapsed && (
          <Box
            className="laptopBox"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <div className="d-flex w-100 justify-content-center">
              <img
                src={user}
                alt="user photo"
                style={{ marginTop: "10px", width: "50px", height: "50px" }}
              />
            </div>
            <p className="mb-0 mt-4 text-center" style={{ fontSize: "20px" }}>
              {activeUser?.name}
            </p>
            <p className="text-center" style={{ fontSize: "14px" }}>
              {activeUser?.role}
            </p>
          </Box>
        )}
      </Box>

      <Box
        className="linksBox"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        {routes.map((route, index) => (
          <React.Fragment key={route.path}>
            <Link
              to={route.path}
              className={
                "sideLink " +
                (activeRoute === route.routeActiveHandler ? "active" : "")
              }
              onClick={() => setActiveRoute(route.routeActiveHandler)}
            >
              {route.icon ? (
                <FontAwesomeIcon icon={route.icon} />
              ) : (
                route.imgSrc
              )}
              <span style={{ marginLeft: "35px" }}>{route.label}</span>
            </Link>
          </React.Fragment>
        ))}

        <div onClick={() => setModal2fa(true)}>
          <a className="sideLink">
            <FontAwesomeIcon icon={faCog as IconProp} />
            <span style={{ marginLeft: "35px" }}>Settings</span>
          </a>
        </div>

        <div onClick={logUserOut}>
          <a className="sideLink">
            <FontAwesomeIcon icon={faSignOutAlt as IconProp} />
            <span style={{ marginLeft: "35px" }}>Log out</span>
          </a>
        </div>
      </Box>
      {!collapsed && (
        <div className="d-flex justify-content-center">
          <img className="bottomLogo" src={avantLogo} />
        </div>
      )}
    </Box>
  );
};

export default Sidebar;
