import ApiRequests from "../apiRequests";

export class CategoriesApi extends ApiRequests {
  constructor() {
    super("categories");
  }
}

export class SubcategoriesApi extends ApiRequests {
  constructor() {
    super("subcategories");
  }
}

export class ProductsApi extends ApiRequests {
  constructor() {
    super("products");
  }

  async exportCSV() {
    let { data } = await this.doRequest(
      `${this.baseUrl}/${this.resource}/export/csv`,
      {},
      "GET"
    );
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "text/csv" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `ventura-${new Date().toLocaleDateString("en-GB")}.csv`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async getAllUpdated(payload: {
    attribute: string;
    from: any;
    to: any;
  }): Promise<any> {
    return await this.doRequest(
      `${this.baseUrl}/${this.resource}`,
      payload,
      "GET"
    );
  }
}

export class FilesApi extends ApiRequests {
  constructor() {
    super("file");
  }
}
