import { ReactNode, createContext } from "react";
import { ApiProviderContext } from "./types";

export const apiContext = createContext<ApiProviderContext>({
  state: {
    models: {},
    responses: {},
  },
  onResponse: () => {},
  onModelsUpdate: () => ({
    revert: () => {},
    newState: {
      models: {},
      responses: {},
    },
    previousState: {
      models: {},
      responses: {},
    },
  }),
  setState: (state: any) => {},
  makeRequest: () => Promise.reject(),
});
