import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useState } from "react";

import CustomButton from "../../../../components/CustomButton/CustomButton";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Collapse from "@mui/material/Collapse";
import { IAudit, IChanges } from "interfaces/Audit";
import { IProduct } from "../../../../interfaces/Products";
import React from "react";
import { useApiHook } from "providers/ApiProvider";
import { startOfDay, endOfDay, addDays, addMinutes } from "date-fns";
import orderBy from "lodash/orderBy";
import pick from "lodash/pick";
import categories from "configs/categories.tableconfig";
import { useParams } from "react-router-dom";

type Column = NonNullable<
  {
    [Key in keyof IProduct]: {
      key: Key;
      label: string;
      getValue?: (value?: IProduct[Key]) => string;
    };
  }[keyof IProduct]
>;

interface IProps {
  lastChanged?: string;
  onDismiss: Function;
  changedProducts: IChanges<IProduct>[];
  // audits: IAudit[];
}

export const ChangedProductsSection = (
  {
    // lastChanged,
    // onDismiss,
    // changedProducts,
  }
) =>
  // : IProps
  {
    const { id }: { id: string } = useParams();

    const [viewUpdatedProducts, setViewUpdatedProducts] = useState(false);

    const [q, setQ] = useState("");

    const [selectedSubcategory, setSelectedSubcategory] = useState<
      string | undefined
    >(undefined);

    const [columnSort, setColumnSort] = useState("updated_at");
    const [columnSortDir, setColumnSortDir] = useState<"asc" | "desc">("desc");

    const [lastUpdatedProductDate, setLastUpdatedProductDate] =
      useState<string>();
    const [_lastUpdatedProductDate, _setLastUpdatedProductDate] =
      useState<string>(); // this is in order to not show the updated bar after the products is updated

    const [now, setNow] = useState(Date.now());

    const past1Minutes = useMemo(() => {
      return addMinutes(now, -0.8).getTime();
    }, [now]);

    const yesterday = useMemo(() => {
      return addDays(now, -1).getTime();
    }, [now]);

    const products = useApiHook("Products", "getAllResources", {
      acceptsCreate: true,
    });

    const productsAudits = useApiHook("Audits", "getAllResources", {
      query: {
        auditedCollection: "product",
        operation: "UPDATE",
        "audited_at[from]": startOfDay(yesterday).getTime(),
        "audited_at[to]": endOfDay(now).getTime(),
      },
      watchResponse: products.responseKey,
      acceptsCreate: true,
    });

    const categories = useApiHook("Categories", "getAllResources", {});
    const category = useApiHook("Categories", "getSpecificResource", id, {});

    const filteredProducts = useMemo(() => {
      let _products = (products.data?.data || []).map((product) => {
        product["updated_at"] = new Date(
          product["updated_at"] || product["created_at"] || 0
        ).getTime();
        return product;
      });

      if (id) {
        _products = _products.filter(
          (p) => p.category?._id === id || p.category === id
        );
      }

      if (selectedSubcategory) {
        _products = _products.filter(
          (p) =>
            p.subcategory?._id === selectedSubcategory ||
            p.subcategory === selectedSubcategory
        );
      }

      // fixing categories models
      _products = _products.map((product) => {
        if (typeof product.category === "string") {
          product.category =
            categories.data?.data?.find((c) => c._id === product.category) ||
            product.category;
        }

        if (typeof product.subcategory === "string") {
          product.subcategory =
            category.data?.data[0]?.subcategories?.find(
              (c) => c._id === product.subcategory
            ) || product.subcategory;
        }
        return product;
      });

      if (q.trim()) {
        const qRegex = new RegExp(q.trim(), "i");
        _products = _products.filter((product) => {
          return qRegex.test(
            [
              product?.model_number,
              product?.description,
              product?.subcategory?.name,
            ].join("")
          );
        });
      }
      return orderBy(_products, [columnSort], [columnSortDir]);
    }, [
      products.data?.data,
      q,
      selectedSubcategory,
      id,
      columnSort,
      columnSortDir,
    ]);

    const changedProducts = useMemo(() => {
      const audits = (productsAudits.data?.data || []) as IAudit<
        "product",
        IProduct
      >[];

      // group audits by entity
      const groupedChangedModel = audits.reduce(
        (acc, audit) => ({
          ...acc,
          [audit.entity]: [...(acc[audit.entity] ?? []), audit],
        }),
        {}
      );

      // sort the grouped audits by audited_at
      const sortedChangedModels = Object.keys(groupedChangedModel).reduce(
        (acc, key) => ({
          ...acc,
          [key]: orderBy(
            groupedChangedModel[key],
            ["audited_at"],
            ["desc"]
          ).filter((a) => !!a.data),
        }),
        {}
      );

      const changes: IChanges<IProduct>[] = [];

      for (const entity in sortedChangedModels) {
        // if the product is deleted or don't exist with current filters, skip
        if (!filteredProducts.find((p) => p._id === entity)) continue;

        const group = sortedChangedModels[entity] as IAudit[];
        const first = group[0];
        const last = group.length > 1 ? group[group.length - 1] : group[0];

        // Get a list of all the keys in the first and last object which we will be comparing
        const keys = Array.from(
          new Set(
            Object.keys(first.data || {}).concat(
              Object.keys(last?.before || {})
            )
          ).values()
        );

        // We fetch the properties that were changed, going over each property
        const changedProperties = keys.filter(
          (k) =>
            !["files"].includes(k) && first?.data?.[k] !== last?.before?.[k]
        );

        // If no properties were updated, then we won't include this in the list
        if (changedProperties.length === 0) continue;

        changes.push({
          entity: first.entity,
          model: { ...last?.before, ...first.data },
          changedProperties,
          changed_at: first.audited_at,
          changedTo: pick(first.data, changedProperties),
          changedFrom: pick(last?.before || {}, changedProperties),
          operation: first.operation,
        });
      }

      return orderBy(changes, ["changed_at"], ["desc"]);
    }, [productsAudits]);

    useEffect(() => {
      // console.log({ changedProducts });
      if (!changedProducts.length) {
        setLastUpdatedProductDate("");
        _setLastUpdatedProductDate("");
        return;
      }

      const d = new Date(changedProducts[0]?.changed_at);
      if (d?.getTime() > 0) {
        if (_lastUpdatedProductDate !== d?.toLocaleString()) {
          setLastUpdatedProductDate(d?.toLocaleString());
          _setLastUpdatedProductDate(d?.toLocaleString());
        }
      }
    }, [changedProducts, id]);

    return (
      <>
        {!!lastUpdatedProductDate ? (
          <>
            <Card
              sx={{
                padding: 2,
                marginBottom: 2,
                backgroundColor: "hsl(219, 80%, 80%)",
                borderRadius: 5,
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <CustomButton
                  sx={{ color: "white", gap: "8px" }}
                  variant="contained"
                  onClick={() => setViewUpdatedProducts(true)}
                >
                  <FontAwesomeIcon icon={faEye as IconProp} className="icon" />
                  <Typography>View Updated Products</Typography>
                </CustomButton>

                <Typography color="inherit" variant="h4">
                  A number of products were last updated on{" "}
                  {lastUpdatedProductDate}
                </Typography>

                <CustomButton
                  sx={{ color: "white" }}
                  variant="contained"
                  onClick={() => setLastUpdatedProductDate("")}
                >
                  Dismiss
                </CustomButton>
              </Box>
            </Card>
            <Dialog
              maxWidth="md"
              fullWidth
              open={viewUpdatedProducts}
              onClose={() => setViewUpdatedProducts(false)}
            >
              <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h3" sx={{ flex: 1 }}>
                  Recently updated products
                </Typography>
                <IconButton onClick={() => setViewUpdatedProducts(false)}>
                  <FontAwesomeIcon
                    icon={faTimes as IconProp}
                    className="icon"
                  />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell style={{ fontWeight: "bold" }}>
                        Model
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Description Name
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Action
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Action Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {changedProducts?.map((changedProduct) => (
                      <ChangedProductRow
                        changedProduct={changedProduct}
                        key={changedProduct.entity}
                      />
                    ))}
                  </TableBody>
                </Table>
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

const ChangedProductRow = ({
  changedProduct,
}: {
  changedProduct: IChanges<IProduct>;
}) => {
  const [open, setOpen] = useState(false);

  const changedKeys = changedProduct.changedProperties;

  // const categories = useApiHook("Categories", "getAllResources", {});
  // const subcategories = useApiHook("Subcategories", "getAllResources", {});

  const viewChanges: Column[] = [
    // { key: "model_number", label: "Model Number" },
    // { key: "description", label: "Description" },

    {
      key: "special_wholesale_price",
      label: "Special Price",
      getValue: (value) =>
        value !== undefined && +(value || "") >= 0 ? `€ ${value}` : "",
    },
    {
      key: "cash_price",
      label: "Cash Price",
      getValue: (value) =>
        value !== undefined && +(value || "") >= 0 ? `€ ${value}` : "",
    },
    {
      key: "retail_price",
      label: "Retail Price",
      getValue: (value) =>
        value !== undefined && +(value || "") >= 0 ? `€ ${value}` : "",
    },
    {
      key: "best_cash_price",
      label: "Best Price",
      getValue: (value) =>
        value !== undefined && +(value || "") >= 0 ? `€ ${value}` : "",
    },
    {
      key: "your_price",
      label: "Your Price",
      getValue: (value) =>
        value !== undefined && +(value || "") >= 0 ? `€ ${value}` : "",
    },

    {
      key: "is_available",
      label: "Availability",
      getValue: (value) => (value ? "In Stock" : "Out of Stock"),
    },
    {
      key: "available_eta",
      label: "Available ETA",
      getValue: (value) => (value ? new Date(value)?.toLocaleDateString() : ""),
    },

    // { key: "product_size", label: "Product Size" },
    // { key: "guarantee", label: "Guarantee" },
    // {
    //   key: "category",
    //   label: "Category",
    //   getValue: (cId) =>
    //     categories?.data?.data.find((cat) => cat?._id === cId)?.name,
    // },
    // {
    //   key: "subcategory",
    //   label: "Subcategory",
    //   getValue: (cId) =>
    //     subcategories?.data?.data.find((cat) => cat?._id === cId)?.name,
    // },
    // { key: "product_variant", label: "Product Variant" },
  ];

  const viewableChanges = changedKeys.filter((key) =>
    viewChanges.find((vc) => vc.key === key)
  );

  return (
    <>
      <TableRow
        onClick={() => (viewableChanges.length > 0 ? setOpen(!open) : null)}
        sx={{
          "& > *": { borderBottom: "unset" },
          cursor: viewableChanges.length > 0 ? "pointer" : "default",
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{
              display: viewableChanges.length > 0 ? "block" : "none",
            }}
          >
            {open ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </IconButton>
        </TableCell>
        <TableCell>{changedProduct.model.model_number}</TableCell>
        <TableCell>{changedProduct.model.description}</TableCell>
        <TableCell>{changedProduct.operation}</TableCell>
        <TableCell>
          {new Date(changedProduct.changed_at!)?.toLocaleString()}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              display={"grid"}
              gridTemplateColumns={"repeat(auto-fill, minmax(12em, 1fr))"}
              gap={2}
              py={2}
            >
              {viewChanges.map((viewChange) => (
                <React.Fragment key={viewChange.key}>
                  {changedKeys.includes(viewChange.key) &&
                    (viewChange.key === "available_eta"
                      ? changedProduct.changedTo?.is_available === false &&
                        !!changedProduct.changedTo?.available_eta
                      : true) && (
                      <>
                        <Box
                          display={"flex"}
                          flexWrap={"wrap"}
                          alignItems="baseline"
                        >
                          <b style={{ flex: "1 0 100%" }}>
                            {viewChange.label}:
                          </b>
                          {viewChange.getValue ? (
                            <>
                              <span style={{ display: "inline-block" }}>
                                {changedProduct.changedFrom &&
                                  viewChange.getValue(
                                    changedProduct.changedFrom?.[viewChange.key]
                                  )}
                              </span>
                              <mark style={{ display: "inline-block" }}>
                                ➜&nbsp;
                                {(changedProduct.changedFrom &&
                                  viewChange.getValue(
                                    changedProduct.changedTo?.[viewChange.key]
                                  )) ?? <>&nbsp;&nbsp;&nbsp;</>}
                              </mark>
                            </>
                          ) : (
                            <>
                              <span>
                                {changedProduct.changedFrom &&
                                  changedProduct.changedFrom?.[viewChange.key]}
                              </span>
                              <mark style={{ display: "inline-block" }}>
                                ➜&nbsp;
                                {(changedProduct.changedFrom &&
                                  changedProduct.changedTo?.[
                                    viewChange.key
                                  ]) ?? <>&nbsp;&nbsp;&nbsp;</>}
                              </mark>
                            </>
                          )}
                        </Box>
                      </>
                    )}
                </React.Fragment>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
