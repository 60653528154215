import { ApiProviderProps } from "providers/ApiProvider/types";
import { createUpdatedProductAuditModel } from "./createProductAuditModel";
import { IProduct } from "interfaces/Products";

export const ApiMiddleware: ApiProviderProps["middleware"] = {
  onModelsUpdate(args, context) {
    if (args.modelKey === "Products" && args.action === "update") {
      const newAudits = args.models.map((changed) => {
        const product =
          context.state.models[args.modelKey]?.[changed._id] || changed;
        return createUpdatedProductAuditModel(
          product as IProduct,
          changed as IProduct
        );
      });

      context.onModelsUpdate({
        modelKey: "Audits",
        action: "create",
        models: newAudits,
      });
    }

    return args;
  },
};
