import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHamburger } from "@fortawesome/free-solid-svg-icons/faHamburger";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import useStoreSessionSelector from "hooks/useStoreSessionSelector";
import { SKIP, useApiHook } from "providers/ApiProvider";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SidebarItem } from "../../interfaces/SidebarItem";
import { logout } from "../../store/actions/session";
import { ConfirmDialog } from "../Dialogs";
import "./MobileMenu.scss";

const StyledMobileMenu = styled(Box)`
  background-color: #132034;

  display: flex;
  flex-wrap: wrap;

  .menuButton {
    margin-right: ${({ theme }) => theme.spacing(2)};
    color: #fff;
    padding: 0;
    margin: 0;
  }

  .menuList {
    width: 100%;
    height: 100%;
    list-style: none;
    background-color: #132034;
    transition: 0.5s all;
  }

  .menuListItem {
    color: #fff;
    margin: 10px 0px;
    padding: 10px;
    width: 100%;
    background-color: rgba(255 255 255 / 0.05);
  }

  .title {
    flex-grow: 1;
  }

  .logo {
    height: 3em;
    display: inline-block;
    margin-left: auto;
  }

  .MuiCollapse-root {
    flex: 1 0 100%;
  }
`;

const MobileMenu = () => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const history = useHistory();

  const session = useStoreSessionSelector();
  const userResponse = useApiHook(
    "Authentication",
    "getSpecificResource",
    session?.token ? "me" : SKIP,
    {}
  );

  const activeUser = userResponse?.data?.result;

  const initConfirmConfig = {
    title: "",
    body: "",
    visible: false,
  };

  const [confirmConfig, setConfirmConfig] = useState(initConfirmConfig);

  const regularRoutes = [
    {
      path: "/categories",
      label: "Categories",
      routeActiveHandler: "categories",
      icon: faHamburger as IconProp,
    },
    {
      path: "/productPrices/categories",
      label: "Product Prices",
      routeActiveHandler: "productPrices/categories",
      icon: faDollarSign as IconProp,
    },
  ];

  const adminRoutes = [
    {
      path: "/users",
      label: "Users",
      routeActiveHandler: "users",
      icon: faUser as IconProp,
    },
    ...regularRoutes,
  ];

  const routes: SidebarItem[] = useMemo(() => {
    if (activeUser?.role === "admin") {
      return adminRoutes;
    }

    return regularRoutes;
  }, [activeUser?.role]);

  const confirmLogout = () => {
    setConfirmConfig({
      title: "Please Confirm",
      body: "Are you sure you want to log out?",
      visible: true,
    });
  };

  const navigateTo = (route: string) => {
    history.push(route);
    setMenu(false);
  };

  const logoutUser = () => {
    dispatch(logout());
  };
  return (
    <>
      <ConfirmDialog
        confirmConfig={confirmConfig}
        executeActionCallback={logoutUser}
        setConfirmConfig={setConfirmConfig}
      />
      <StyledMobileMenu className={" p-3"}>
        <IconButton
          onClick={() => setMenu(!menu)}
          className={"menuButton"}
          aria-label="menu"
        >
          <FontAwesomeIcon icon={faBars as IconProp} />
        </IconButton>

        <img src="/LogoWhite.svg" className="logo" />
        <Collapse in={menu}>
          <Box
            className={"menuList"}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <List className={"menuList"}>
              {routes.map((route, index) => (
                <ListItem
                  key={route.path || index}
                  className={"menuListItem"}
                  onClick={() => navigateTo(route.path)}
                >
                  {/* @ts-ignore */}
                  <FontAwesomeIcon
                    icon={route?.icon as IconProp}
                    style={{ marginRight: "20px" }}
                  />
                  <Typography variant="body1">{route.label}</Typography>
                </ListItem>
              ))}
              <ListItem
                className={"menuListItem"}
                onClick={() => confirmLogout()}
              >
                <FontAwesomeIcon
                  icon={faSignOutAlt as IconProp}
                  style={{ marginRight: "20px" }}
                />
                <Typography variant="body1">Log Out</Typography>
              </ListItem>
            </List>
          </Box>
        </Collapse>
        {/* {menu && (
                    <>
                        
                    </>
                )} */}
      </StyledMobileMenu>
    </>
  );
};

export default MobileMenu;
