import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ICategory } from "../../../interfaces/Category";
import { IProduct } from "../../../interfaces/Products";

interface ProductFormProps {
  product: IProduct;
  handleChange: any;
  categoriesLoading: boolean;
  categoryOptions: ICategory[];
  subcategoriesLoading: boolean;
  subcategoryOptions: ICategory[];
  setProduct: React.Dispatch<React.SetStateAction<IProduct>>;
  permissionChecker(permission: string): boolean;
  disabled: boolean;
}

const AddEditViewProductForm = ({
  product,
  setProduct,
  handleChange,
  categoriesLoading,
  categoryOptions,
  subcategoryOptions,
  subcategoriesLoading,
  permissionChecker,
  disabled,
}: ProductFormProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">General</Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <TextField
          variant="standard"
          label="Model Number *"
          name="model_number"
          style={{ marginRight: "10px" }}
          fullWidth
          value={product ? product.model_number : ""}
          onChange={(e) =>
            setProduct({ ...product, model_number: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <FormControl fullWidth>
          <InputLabel id="is_available">Availability *</InputLabel>
          <Select
            value={product.is_available}
            label="is_available"
            name="is_available"
            onChange={(e) => {
              setProduct({
                ...product,
                is_available: !!e.target.value,
                available_eta: !e.target.value ? null : product.available_eta,
              });
            }}
          >
            {/* @ts-ignore */}
            <MenuItem value={true}>In Stock</MenuItem>
            {/* @ts-ignore */}
            <MenuItem value={false}>Out Of Stock</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <TextField
          variant="outlined"
          name="available_eta"
          type="date"
          disabled={product?.is_available}
          // required={!product?.is_available}
          label="Eta Available"
          inputProps={{
            min: new Date().toISOString().split("T")[0],
          }}
          InputLabelProps={{ shrink: true }}
          onChange={(e: any) =>
            setProduct({
              ...product,
              available_eta: new Date(e.currentTarget.value)?.getTime() ?? null,
            })
          }
          sx={{
            "input::-webkit-datetime-edit-fields-wrapper": {
              display: "inline-grid",
              gridAutoFlow: "column",
            },
            "input::-webkit-datetime-edit-month-field": {
              gridColumn: "3 / 4",
              tabIndex: 0,
            },
            "input::-webkit-datetime-edit-day-field": {
              gridColumn: "1 / 2",
              tabIndex: 1,
            },
            "input::-webkit-datetime-edit-year-field": {
              gridColumn: "5 / 6",
              tabIndex: 3,
            },
          }}
          value={
            !product?.is_available && product.available_eta
              ? new Date(product.available_eta).toISOString().split("T")[0]
              : ""
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {/* a button that clears available_eta field */}
                <IconButton
                  size="small"
                  sx={{ marginLeft: -1 }}
                  onClick={() => {
                    setProduct({
                      ...product,
                      available_eta: null,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disablePast
                        disabled={(product ? product.is_available : false)}
                        label="Eta Available"
                        value={product ? product.available_eta : Date.now()}
                        onChange={(e: any) => setProduct({ ...product, available_eta: e.getTime() })}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider> */}
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <TextField
          variant="standard"
          label="Product Size"
          name="product_size"
          style={{ marginRight: "10px" }}
          fullWidth
          value={product ? product.product_size : ""}
          onChange={(e) => handleChange(e)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <FormControl fullWidth>
          <InputLabel id="category-label">Category *</InputLabel>
          <Select
            labelId="category-label"
            name="category"
            value={
              product.category?._id
                ? product.category._id
                : product.category
                ? product.category
                : ""
            }
            onChange={(e: any) =>
              setProduct({
                ...product,
                category: e.target.value,
                subcategory: null,
              })
            }
          >
            {categoriesLoading ? (
              <CircularProgress style={{ fontSize: "16px" }} />
            ) : (
              categoryOptions.map((category, index) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <FormControl fullWidth>
          <InputLabel id="subcategory-label">Subcategory *</InputLabel>
          <Select
            name="subcategory"
            labelId="subcategory-label"
            value={
              product.subcategory?._id
                ? product.subcategory._id
                : product.subcategory
                ? product.subcategory
                : ""
            }
            onChange={(e: any) =>
              setProduct({ ...product, subcategory: e.target.value })
            }
          >
            {subcategoriesLoading ? (
              <CircularProgress style={{ fontSize: "16px" }} />
            ) : (
              subcategoryOptions.map((subcategory: any, index: any) => (
                <MenuItem key={subcategory._id} value={subcategory._id}>
                  {subcategory.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <TextField
          variant="standard"
          fullWidth
          label="Product Variant"
          name="product_variant"
          value={product ? product.product_variant : ""}
          onChange={(e) => handleChange(e)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <TextField
          variant="standard"
          fullWidth
          label="Guarantee"
          name="guarantee"
          value={product ? product.guarantee : ""}
          onChange={(e) => handleChange(e)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          variant="standard"
          label="Description"
          name="description"
          fullWidth
          value={product ? product.description : ""}
          onChange={(e) => handleChange(e)}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Prices</Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
        {permissionChecker("see-wholesale-price") ? (
          <TextField
            variant="standard"
            label="Special Price"
            name="special_wholesale_price"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">&euro;</InputAdornment>
              ),
            }}
            value={product ? product.special_wholesale_price : ""}
            onChange={(e) => handleChange(e)}
          />
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
        {permissionChecker("see-cash-price") ? (
          <TextField
            variant="standard"
            label="Cash Price"
            name="cash_price"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">&euro;</InputAdornment>
              ),
            }}
            value={product ? product.cash_price : ""}
            onChange={(e) => handleChange(e)}
          />
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
        {permissionChecker("see-retail-price") ? (
          <TextField
            variant="standard"
            label="Retail Price"
            name="retail_price"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">&euro;</InputAdornment>
              ),
            }}
            value={product ? product.retail_price : ""}
            onChange={(e) => handleChange(e)}
          />
        ) : (
          ""
        )}
      </Grid>
      {/* second row */}
      <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
        {permissionChecker("see-best-cash-price") ? (
          <TextField
            variant="standard"
            label="Best Price"
            name="best_cash_price"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">&euro;</InputAdornment>
              ),
            }}
            value={product ? product.best_cash_price : ""}
            onChange={(e) => handleChange(e)}
          />
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
        {permissionChecker("see-your-price") ? (
          <TextField
            variant="standard"
            label="Your Price"
            name="your_price"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">&euro;</InputAdornment>
              ),
            }}
            value={product ? product.your_price : ""}
            onChange={(e) => handleChange(e)}
          />
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};
export default AddEditViewProductForm;
