export const SHOW_QR_MODAL = "SHOW_QR_MODAL";
export const HIDE_QR_MODAL = "HIDE_QR_MODAL";

export const showQrModal = (
  imgUrl: string,
  email: string,
  password: string
) => {
  return {
    type: SHOW_QR_MODAL,
    payload: imgUrl,
    email: email,
    password: password,
  };
};

export const hideQrModal = () => {
  return {
    type: HIDE_QR_MODAL,
  };
};
