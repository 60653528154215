import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import React from "react";
import PasswordFields from "./PasswordFields";
import UserRoles from "./UserRoles";

const AddEditUserForm = ({
  object,
  setObject,
  userRole,
  setUserRole,
  emailExists = false,
}: any) => {
  const [password, setPassword] = React.useState<string>("");
  const [tempPassword, setTempPassword] = React.useState<string>("");

  const handleChange = (input: any) => {
    let tempObj: any = object;
    tempObj[input.name] = input.value;
    setObject({ ...tempObj });
  };

  React.useEffect(() => {
    let tempObj = { ...object };
    tempObj.role = userRole;
    setObject(tempObj);
  }, [userRole]);

  React.useEffect(() => {
    let tempObj = { ...object };
    if (password === tempPassword) {
      tempObj.password = password;
    }
    setObject(tempObj);
  }, [password]);

  return (
    <form>
      <TextField
        fullWidth
        label="Full Name"
        name="name"
        value={object ? object.name : ""}
        onChange={(e) => handleChange(e.target)}
        className="my-2"
      />
      <TextField
        fullWidth
        label="Email"
        name="email"
        value={object ? object.email : ""}
        onChange={(e) => handleChange(e.target)}
        className="mt-2"
        error={emailExists}
        helperText={emailExists && "Email is already in use"}
      />
      <PasswordFields
        password={password}
        tempPassword={tempPassword}
        setPassword={setPassword}
        setTempPassword={setTempPassword}
      />
      <div className="mt-3">
        <UserRoles
          object={object}
          userRole={userRole}
          setUserRole={setUserRole}
        />
      </div>
    </form>
  );
};

export default AddEditUserForm;
