import { SKIP, useApiHook } from "providers/ApiProvider";
import { useMemo } from "react";
import { User } from "../interfaces/Users";
import useStoreSessionSelector from "./useStoreSessionSelector";

/**
 * @todo replace with a better pattern, this will trigger infinte loops in useEffect due to refreshing the reference every time
 */
const useUserData = (): User => {
  // const userInfo: User = JSON.parse(sessionStorage.getItem("USER_INFO")!);

  // return useMemo(() => userInfo, [userInfo]);

  const session = useStoreSessionSelector();
  const user = useApiHook(
    "Authentication",
    "getSpecificResource",
    session?.token ? "me" : SKIP,
    {}
  );

  return user.data?.result;
};

export default useUserData;
