import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import placeholderPic from "../../../assets/no_data_x.gif";
import GenericHeader from "../../../components/GenericHeader/GenericHeader";
import GenericTable from "../../../components/GenericTable/GenericTable";
import categoriesTableConfig from "../../../configs/categories.tableconfig";
import useStoreSessionSelector from "../../../hooks/useStoreSessionSelector";
import { ICategory } from "../../../interfaces/Category";
import CategoryService from "../../../services/Entities/categoryService";
import { usePermissionChecker } from "../../../hooks/useRoles";
import { useApiHook, useApiHookCallback } from "providers/ApiProvider";

export const CategoriesTableView = () => {
  const categories = useApiHook("Categories", "getAllResources", {});
  const deleteCategoryCallback = useApiHookCallback(
    "Categories",
    "deleteManyResource"
  );

  // const [list, setList] = useState<ICategory[]>([]);
  const [q, setQ] = useState("");
  const [selectedRows, setSelectedRows] = useState<ICategory[]>([]);
  // const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);

  const session = useStoreSessionSelector();
  const history = useHistory();
  // const entityService = new CategoryService();

  const permissionChecker = usePermissionChecker();

  // let [numberOfData, setNdata] = useState<number>(0);

  const list = useMemo(() => {
    let _categories = categories.data?.data || [];
    if (q.trim()) {
      const qRegex = new RegExp(q.trim(), "i");
      _categories = _categories.filter((product) => {
        return qRegex.test(
          [
            product.model_number,
            product.description,
            product?.subcategory?.name,
          ].join("")
        );
      });
    }

    return _categories;
  }, [categories.data?.data, q]);

  const numberOfData = categories.data?.total || 0;

  const loading = categories.loading || deleteCategoryCallback.loading;

  const handleSearch = (value: string) => {
    setQ(value);
  };

  const deleteEntity = () => {
    if (!permissionChecker("delete-category", true)) return;

    // setLoading(true);

    deleteCategoryCallback.request({
      token: session.token,
      data: selectedRows,
    });
  };

  // React.useEffect(fetchData, [page, rowsPerPage]);

  const navigateToAddPage = () => history.push(`categories/add`);

  const rowTriggerAction = (row: any) => history.push(`categories/${row?._id}`);

  const handlePageChange = (e: any, value: any) => setPage(value);

  const handleRowsChange = (e: { target: { value: string } }) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  };

  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        onSearch={handleSearch}
        addResourceHandler={
          permissionChecker("create-category") ? navigateToAddPage : undefined
        }
        deleteResourceHandler={
          permissionChecker("delete-category") ? deleteEntity : undefined
        }
        selectedRows={selectedRows}
        model="category"
      />
      <div className="p-4">
        <h2 className="mb-4">Categories</h2>
        <GenericTable
          list={list}
          totalCount={list.length}
          onSelectedChange={handleRowSelectionChange}
          selectedRows={selectedRows}
          page={page}
          numberOfData={numberOfData}
          frontendPagination
          // @ts-ignore
          loading={loading}
          rowsPerPage={rowsPerPage}
          onRowsChange={handleRowsChange}
          onPageChange={handlePageChange}
          image={placeholderPic}
          tableConfig={categoriesTableConfig}
          model="category"
          passRow={rowTriggerAction}
        />
      </div>
    </Box>
  );
};
