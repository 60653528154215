import { combineReducers } from "redux";
import alerts from "./alerts";
import session from "./session";
import qrmodal from "./qrmodal";

const rootReducer = combineReducers({
  session,
  alerts,
  qrmodal,
});

export default rootReducer;
