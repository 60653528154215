// eslint-disable
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { SKIP, useApiHook, useApiHookCallback } from "providers/ApiProvider";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import pdf_file from "../../assets/pdf_file.png";
import AddEditViewProductForm from "../../components/PageComponents/Products/AddEditViewProductForm";
import { usePermissionChecker } from "../../hooks/useRoles";
import useStoreSessionSelector from "../../hooks/useStoreSessionSelector";
import { IProduct, IProductFactory } from "../../interfaces/Products";
import {
  CategoriesApi,
  FilesApi,
  ProductsApi,
} from "../../services/Entities/ProductPricingService";
import { showErrorAlert, showSuccessAlert } from "../../store/actions/alerts";
import "./AddEditViewProduct.scss";
import isEqual from "date-fns/isEqual";

const AddEditViewProduct: FC<any> = ({
  id = undefined,
  setModal,
  refreshData,
  modal,
}: any) => {
  // const productsApi = new ProductsApi();
  const filesApi = new FilesApi();

  const product = useApiHook("Products", "getSpecificResource", id || SKIP, {});
  const categories = useApiHook("Categories", "getAllResources", {
    perPage: 10000,
  });
  const productCallback = useApiHookCallback(
    "Products",
    id ? "patchResource" : "postResource"
  );

  const [formData, setFormData] = useState<IProduct>(IProductFactory);

  const subcategories = useApiHook(
    "Categories",
    "getSpecificResource",
    formData?.category?._id || formData?.category || SKIP,
    {}
  );

  useEffect(() => {
    if (product.data) {
      setFormData(product.data?.data[0]);
    }
  }, [product.data]);

  const [userCanModifyForm, setUserCanModifyForm] = useState<boolean>(false);

  const fileUploadRef = useRef<HTMLInputElement>();

  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const loading =
    product.loading ||
    categories.loading ||
    productCallback.loading ||
    requestLoading;

  const session = useStoreSessionSelector();
  const dispatch = useDispatch();

  const permissionChecker = usePermissionChecker();

  useEffect(() => {
    const userCanCreateProduct =
      permissionChecker("create-product") && !formData?._id;
    const userCanEditProduct =
      permissionChecker("edit-product") && !!formData?._id;
    setUserCanModifyForm(userCanCreateProduct || userCanEditProduct);
  }, [permissionChecker, formData]);

  const fileUpload = async () => {
    if (fileUploadRef?.current) {
      setRequestLoading(true);
      const filesToUpload = fileUploadRef?.current?.files || new Array<File>(0);

      try {
        const uploadedFiles = await Promise.all(
          Array.from(filesToUpload).map(async (f) => {
            let fd = new FormData();
            fd?.append("file", f);

            return await filesApi.postResource(fd).then((r) => r.data?.data);
          })
        );

        if (uploadedFiles.length) {
          dispatch(showSuccessAlert("File Added Successfully!"));
          formData?.files?.push(...uploadedFiles);
        }
      } finally {
        if (fileUploadRef.current) {
          fileUploadRef.current.value = "";
        }
        setRequestLoading(false);
      }
    }
  };

  const onSubmit = async () => {
    await fileUpload();

    if (id) {
      formData["id"] = id;
      formData["updated_at"] = new Date().toISOString() as any;

      if (
        id &&
        ![
          "cash_price",
          "retail_price",
          "special_wholesale_price",
          "best_cash_price",
          "your_price",
        ].every((key) => +formData[key] === +product.data?.data[0]?.[key])
      ) {
        formData["price_updated"] = new Date().toISOString() as any;
      }
    }
    await productCallback.request(formData).then((response) => {
      dispatch(
        showSuccessAlert(
          id ? "Product Edited successfully!" : "Product Added Successfully!"
        )
      );
      // product.refresh()
    });
  };

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    //TODO: handle those types boolean and date
    e.preventDefault();
    let tempProduct: IProduct = { ...formData };
    const key: any = e?.target?.name;
    const value: any = e?.target?.value;

    if (Object.keys(formData).includes(key))
      tempProduct = { ...formData, [key]: value };

    setProductWithPermissionCheck(tempProduct);
  };

  const setProductWithPermissionCheck = (data) => {
    if (!userCanModifyForm) return;
    setFormData(data);
  };

  const deleteResourceHandler = (e: any, product: any, file: File) => {
    e.preventDefault(); // So the image doesn't open
    if (!permissionChecker("delete-user", true)) return;

    const result = window.confirm("Are you sure you want to delete?");
    if (!result) return;

    if (!id) {
      dispatch(
        showErrorAlert({
          message: "There is no product to delete! You are in create-mode.",
        })
      );
    } else {
      const payload = {
        id: id,
        token: session.token,
      };
      deleteFile(product, file);
    }
  };

  const deleteFile = (
    product: any,
    file: NonNullable<IProduct["files"]>[number]
  ) => {
    setRequestLoading(true);
    const payload = {
      keys: [file["key"]],
      token: session.token,
    };

    filesApi
      .deleteResource(payload)
      .then((res) => {
        dispatch(showSuccessAlert("File Deleted Successfully!"));
        refreshData(true);
        setFormData((p) => {
          if (p?.files) {
            p.files = p.files.filter((f) => f.key !== file.key);
          }
          return { ...p };
        });
        // setModal(false);
      })
      .finally(() => setRequestLoading(false));
  };

  return (
    <>
      <div className="py-2d">
        <h1 style={{ marginBottom: "20px" }}>
          {id !== undefined ? "Edit Product" : "Add New Product"}
        </h1>
      </div>
      {loading && <CircularProgress />}
      <div
        style={{
          display: loading ? "none" : "block",
        }}
      >
        <>
          <AddEditViewProductForm
            product={formData}
            handleChange={handleChange}
            categoriesLoading={categories.loading}
            categoryOptions={categories.data?.data || []}
            subcategoryOptions={
              subcategories.data?.data?.[0]?.subcategories || []
            }
            subcategoriesLoading={subcategories.loading}
            setProduct={setProductWithPermissionCheck}
            permissionChecker={permissionChecker}
            disabled={!userCanModifyForm}
          />

          <Typography variant="h3" style={{ paddingTop: 25 }}>
            {formData?.files?.length ?? 0} Files Attached
          </Typography>

          <div className="imagesContainer">
            {!!formData?.files?.length &&
              formData?.files.map((file: any) => (
                <div className="imageHolder">
                  <a href={file.url} target="_blank" className="flyerUrl">
                    <img
                      src={file.type == "image" ? file.url : pdf_file}
                      className={
                        file.type == "image" ? "flyerImage" : "iconImage"
                      }
                    />
                    <button
                      disabled={!userCanModifyForm}
                      className="fileDelete"
                      onClick={(e) => deleteResourceHandler(e, formData, file)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ float: "left" }}
                      />
                    </button>
                  </a>
                </div>
              ))}
          </div>

          <div className="mt-3" style={{ transition: "0.3s all" }}>
            <p
              className="mb-0"
              style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" }}
            >
              Attach a file
            </p>
            <input
              disabled={!userCanModifyForm}
              name="files"
              type="file"
              multiple
              ref={(current) => Object.assign(fileUploadRef, { current })}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ display: "flex" }}>
              <button
                className="cancelBtn"
                style={{ margin: "15px 0px" }}
                onClick={() => setModal(false)}
              >
                Cancel
              </button>

              <button
                className="submitBtn"
                style={{ margin: "15px" }}
                disabled={
                  !formData?.category ||
                  !formData?.subcategory ||
                  !formData?.model_number ||
                  loading ||
                  !userCanModifyForm
                }
                onClick={onSubmit}
              >
                {loading ? (
                  <CircularProgress style={{ width: "30px" }} />
                ) : id === undefined ? (
                  "Add Product"
                ) : (
                  "Edit Product"
                )}
              </button>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default AddEditViewProduct;
