import { createContext, useContext, useEffect, useState } from "react";
import ShoppingCartModal from "./modal";
import { IShoppingCartContext, IShoppingCartProduct } from "./types";
import { ShoppingCartContext, createShoppingCartContext } from "./context";

export const ShoppingCartProvider = ({ children }: { children: any }) => {
  const [tax, setTax] = useState<number | undefined>(0);
  const [discount, setDiscount] = useState<number | undefined>(undefined);
  const [shipping, setShipping] = useState<number | undefined>(undefined);

  const [rows, setRows] = useState<IShoppingCartProduct[]>(() => {
    try {
      if (sessionStorage.getItem("ShoppingCartProducts")) {
        return JSON.parse(
          sessionStorage.getItem("ShoppingCartProducts") || "[]"
        );
      }
    } catch (e) {}
    return [];
  });

  useEffect(() => {
    const debounce = setTimeout(() => {
      sessionStorage.setItem("ShoppingCartProducts", JSON.stringify(rows));
    }, 500);

    return () => clearTimeout(debounce);
  }, [rows]);

  const [quoteModal, setQuoteModal] = useState(false);

  const getBestTotal = () => {
    return rows?.reduce(
      (acc, r) => acc + (isNaN(r.bestPrice!) ? 0 : r.bestPrice!),
      0
    );
  };

  const getRetailTotal = () => {
    return rows?.reduce(
      (acc, r) => acc + (isNaN(r.retailPrice!) ? 0 : r.retailPrice!),
      0
    );
  };

  const [keepMounted, setKeepMounted] = useState(true);

  const reset = () => {
    let modal = quoteModal;
    setKeepMounted(false);
    setQuoteModal(false);
    setRows([]);
    setTax(undefined);
    setDiscount(undefined);
    setShipping(undefined);

    const sessionShoppingCartKeys = Object.keys(sessionStorage).filter((k) =>
      /ShoppingCart/gi.test(k)
    );

    for (const key of sessionShoppingCartKeys) {
      console.log(key);
      sessionStorage.removeItem(key);
    }

    setTimeout(() => {
      setKeepMounted(true);
      setQuoteModal(modal);
    }, 500);
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        rows,
        setRows,
        tax,
        setTax,
        discount,
        setDiscount,
        shipping,
        setShipping,
        getBestTotal,
        getRetailTotal,
        reset,
        openCart: () => setQuoteModal(true),
        addToCart: (ps: Partial<IShoppingCartProduct>[]) => {
          setRows(
            rows.concat(
              ps.map((p) => ({
                _id: (performance.now() * Math.random()).toString(16),
                quantity: 1,
                bestPrice: p?.bestPrice || p?.product?.best_cash_price,
                retailPrice: p?.retailPrice || p?.product?.retail_price,
                ...p,
              }))
            )
          );
        },
      }}
    >
      <ShoppingCartModal
        keepMounted={keepMounted}
        open={quoteModal}
        onClose={() => setQuoteModal(false)}
      />
      {children}
    </ShoppingCartContext.Provider>
  );
};
