import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faFileImage } from "@fortawesome/free-solid-svg-icons/faFileImage";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons/faAngleDoubleLeft";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  addDays,
  addMinutes,
  differenceInMilliseconds,
  endOfDay,
  isBefore,
  startOfDay,
} from "date-fns";
import dayjs from "dayjs";
import { IAudit, IChanges } from "interfaces/Audit";
import orderBy from "lodash/orderBy";
import pick from "lodash/pick";
import { useApiHook, useApiHookCallback } from "providers/ApiProvider";
import { NavLink, useParams } from "react-router-dom";
import placeholderPic from "../../assets/no_data_x.gif";
import CustomButton from "../../components/CustomButton/CustomButton";
import GenericHeader from "../../components/GenericHeader/GenericHeader";
import GenericTable from "../../components/GenericTable/GenericTable";
import "../../components/GenericTable/GenericTable.scss";
import Modal from "../../components/Modal/Modal";
import { useShoppingCart } from "../../components/ShoppingCart/context";
import productsTableConfig from "../../configs/productsTable";
import { usePermissionChecker } from "../../hooks/useRoles";
import useStoreSessionSelector from "../../hooks/useStoreSessionSelector";
import { ICategory } from "../../interfaces/Category";
import { IProduct } from "../../interfaces/Products";
import { TableConfig } from "../../interfaces/tableConfig";
import { ProductsApi } from "../../services/Entities/ProductPricingService";
import AddEditViewProduct from "../AddEditViewProduct/AddEditViewProduct";
import { ProductPrintModal } from "../ProductPrintModal/ProductPrintModal";
import "./ProductPriceCategory.scss";
import { ChangedProductsSection } from "./components/ChangedProductsSection/ChangedProductsSection";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import NoResourceAvailable from "components/NoResourceAvailable/NoResourceAvailable";

const MS_1_MINUTES = differenceInMilliseconds(addMinutes(0, 1), 0);

const ProductPriceCategory = () => {
  const { id }: { id: string } = useParams();

  // Libs
  const productsApi = new ProductsApi();
  const permissionChecker = usePermissionChecker();
  const session = useStoreSessionSelector();
  const shoppingCart = useShoppingCart();

  const [now, setNow] = useState(Date.now());

  const past1Minutes = useMemo(() => {
    return addMinutes(now, -0.8).getTime();
  }, [now]);

  const yesterday = useMemo(() => {
    return addDays(now, -1).getTime();
  }, [now]);

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // UI
  const [activeRow, setActiveRow] = useState(null);
  const [selected, setSelected] = useState<any>([]);
  const [collapsed, setCollapsed] = useState(false);
  const [modal, setModal] = useState(false);
  const [active, setActive] = useState<ICategory>({});
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [filteredProductColumns, setFilteredProductColumns] = useState<
    TableConfig[]
  >([]);
  const [lastUpdatedProductDate, setLastUpdatedProductDate] =
    useState<string>();
  const [_lastUpdatedProductDate, _setLastUpdatedProductDate] =
    useState<string>(); // this is in order to not show the updated bar after the products is updated
  const [showSelected, setShowSelected] = useState(false);
  const categoryButtonRef = useRef<any>();
  const [showCategoriesMenu, setShowCategoriesMenu] = useState(false);

  // Query States
  const [q, setQ] = useState("");

  const [selectedQuery, setSelectedQuery] = useState("");
  const selectedQueryRegex = useMemo(
    () => new RegExp(selectedQuery?.trim(), "i"),
    [selectedQuery]
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const [subCatCurrentPage, setSubCatCurrentPage] = useState(1);
  const [subCatItemsPerPage, setSubCatItemsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const [productPage, setProductPage] = useState(0);
  const [productsRowsPerPage, setProductsRowsPerPage] = useState(50);

  const [columnSort, setColumnSort] = useState("updated_at");
  const [columnSortDir, setColumnSortDir] = useState<"asc" | "desc">("desc");

  // Working States
  const [selectedSubcategory, setSelectedSubcategory] = useState<
    string | undefined
  >(undefined);
  const [productSelectedRowsIds, setProductSelectedRowsIds] = useState<
    string[]
  >([]);
  const [productSelectedRows, setProductSelectedRows] = useState<IProduct[]>(
    []
  );
  const [selectedProduct, setSelectedProduct] = useState<string | undefined>(
    undefined
  );

  const products = useApiHook("Products", "getAllResources", {
    acceptsCreate: true,
  });

  // const productsAudits = useApiHook("Audits", "getAllResources", {
  //   query: {
  //     auditedCollection: "product",
  //     "audited_at[from]": startOfDay(yesterday).getTime(),
  //     "audited_at[to]": endOfDay(now).getTime(),
  //   },
  //   watchResponse: products.responseKey,
  //   acceptsCreate: true,
  // });

  const categories = useApiHook("Categories", "getAllResources", {});
  const category = useApiHook("Categories", "getSpecificResource", id, {});

  const filteredProducts = useMemo(() => {
    let _products = (products.data?.data || []).map((product) => {
      product["updated_at"] = new Date(
        product["updated_at"] || product["created_at"] || 0
      ).getTime();
      return product;
    });

    if (id) {
      _products = _products.filter(
        (p) => p.category?._id === id || p.category === id
      );
    }

    if (selectedSubcategory) {
      _products = _products.filter(
        (p) =>
          p.subcategory?._id === selectedSubcategory ||
          p.subcategory === selectedSubcategory
      );
    }

    // fixing categories models
    _products = _products.map((product) => {
      if (typeof product.category === "string") {
        product.category =
          categories.data?.data?.find((c) => c._id === product.category) ||
          product.category;
      }

      if (typeof product.subcategory === "string") {
        product.subcategory =
          category.data?.data[0]?.subcategories?.find(
            (c) => c._id === product.subcategory
          ) || product.subcategory;
      }
      return product;
    });

    if (q.trim()) {
      const qRegex = new RegExp(q.trim(), "i");
      _products = _products.filter((product) => {
        return qRegex.test(
          [
            product?.model_number,
            product?.description,
            product?.subcategory?.name,
          ].join("")
        );
      });
    }
    return orderBy(_products, [columnSort], [columnSortDir]);
  }, [
    products.data?.data,
    q,
    selectedSubcategory,
    id,
    columnSort,
    columnSortDir,
  ]);

  useEffect(() => {
    _setLastUpdatedProductDate("");
    setLastUpdatedProductDate("");
    setShowCategoriesMenu(false);
  }, [id]);

  const deleteProductCallback = useApiHookCallback(
    "Products",
    "deleteManyResource"
  );

  // const changedProducts = useMemo(() => {
  //   const audits = (productsAudits.data?.data || []) as IAudit<
  //     "product",
  //     IProduct
  //   >[];

  //   // group audits by entity
  //   const groupedChangedModel = audits.reduce(
  //     (acc, audit) => ({
  //       ...acc,
  //       [audit.entity]: [...(acc[audit.entity] ?? []), audit],
  //     }),
  //     {}
  //   );

  //   // sort the grouped audits by audited_at
  //   const sortedChangedModels = Object.keys(groupedChangedModel).reduce(
  //     (acc, key) => ({
  //       ...acc,
  //       [key]: orderBy(
  //         groupedChangedModel[key],
  //         ["audited_at"],
  //         ["desc"]
  //       ).filter((a) => !!a.data),
  //     }),
  //     {}
  //   );

  //   const changes: IChanges<IProduct>[] = [];

  //   for (const entity in sortedChangedModels) {
  //     // if the product is deleted or don't exist with current filters, skip
  //     if (!filteredProducts.find((p) => p._id === entity)) continue;

  //     const group = sortedChangedModels[entity];
  //     const first = group[0];
  //     const last = group.length > 1 ? group[group.length - 1] : undefined;

  //     if (first?.operation !== "UPDATE") continue;

  //     const keys = Array.from(
  //       new Set(
  //         Object.keys(first.data || {}).concat(Object.keys(last?.data || {}))
  //       ).values()
  //     );

  //     const changedProperties = keys.filter(
  //       (k) =>
  //         ["CREATE", "DELETE"].includes(first.operation) ||
  //         (!["files"].includes(k) && first?.data?.[k] !== last?.data?.[k])
  //     );

  //     if (changedProperties.length === 0) continue;

  //     changes.push({
  //       entity: first.entity,
  //       model: { ...last?.data, ...first.data },
  //       changedProperties,
  //       changed_at: first.audited_at,
  //       changedTo: pick(first.data, changedProperties),
  //       changedFrom: pick(last?.data || {}, changedProperties),
  //       operation: first.operation,
  //     });
  //   }

  //   return orderBy(changes, ["changed_at"], ["desc"]);
  // }, [productsAudits]);

  // TABLE FUNCTIONS

  const renderFiles = (row: any, object: any): any => {
    let data: any = row[object.attribute];
    let max_files: number = 2;

    if (Array.isArray(data)) {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "2px" }}
        >
          {data.length == 0
            ? "none"
            : data.map((file, index) =>
                index >= max_files ? (
                  ""
                ) : (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    key={file.url}
                    href={file.url}
                    style={{ marginRight: "8px" }}
                  >
                    <FontAwesomeIcon
                      style={{ paddingLeft: "12px" }}
                      fontSize={30}
                      color="#b54040"
                      icon={
                        file.type == "image"
                          ? (faFileImage as IconProp)
                          : (faFile as IconProp)
                      }
                      className="fontAwesomeIcon"
                    />
                  </a>
                )
              )}
          <>{data.length > max_files ? "..." : ""}</>
        </div>
      );
    } else {
      return "not a files array...";
    }
  };

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredProducts.length);

  const currentItems = filteredProducts.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const subCatTotalPages = Math.ceil(
    category?.data?.data[0]?.subcategories.length / subCatItemsPerPage
  );

  const subCatstartIndex = (subCatCurrentPage - 1) * subCatItemsPerPage;
  const subCatEndIndex = Math.min(
    subCatstartIndex + subCatItemsPerPage,
    category?.data?.data[0]?.subcategories.length
  );

  const subCatCurrentItems = category?.data?.data[0]?.subcategories.slice(
    subCatstartIndex,
    subCatEndIndex
  );

  const handleSubCatPageChange = (page) => {
    setSubCatCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handleSubCatItemsPerPageChange = (event) => {
    setSubCatItemsPerPage(parseInt(event.target.value));
    setSubCatCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = React.useMemo(() => {
    if (sortConfig.key) {
      return [...currentItems].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return currentItems;
  }, [currentItems, sortConfig]);

  const handleSelectionChange = (rowId: any) => {
    let data = [...productSelectedRowsIds];
    if (data.includes(rowId)) {
      data = data.filter((item) => item !== rowId);
    } else {
      data.push(rowId);
    }
    setSelected(data);
    onProductsSelect(data);
  };

  const selectAll = (checked: any) => {
    let dataList = [...productSelectedRowsIds];
    if (checked) {
      filteredProducts.forEach((item: any) => {
        if (!dataList.includes(item._id)) {
          dataList.push(item._id);
        }
      });
      dataList = [...new Set(dataList)];
      setSelected(dataList);
    } else {
      filteredProducts.forEach((item: any) => {
        dataList = dataList.filter((listItem) => listItem !== item?._id);
      });
      setSelected(dataList);
    }
    onProductsSelect(dataList);
  };

  const isSelectedAll = useMemo(() => {
    if (productSelectedRowsIds?.length === 0) return false;

    // // if the selected and the list sizes don't match, then obviously not all are selected
    // if (params.selectedRows?.length !== params.list?.length) return false;

    return filteredProducts?.every((item) =>
      productSelectedRowsIds.includes(item["_id"])
    );
  }, [productSelectedRowsIds, filteredProducts]);

  // useEffect(() => {
  //   // console.log({ changedProducts });
  //   if (!changedProducts.length) {
  //     setLastUpdatedProductDate("");
  //     _setLastUpdatedProductDate("");
  //     return;
  //   }

  //   const d = new Date(changedProducts[0]?.changed_at);
  //   if (d?.getTime() > 0) {
  //     if (_lastUpdatedProductDate !== d?.toLocaleString()) {
  //       setLastUpdatedProductDate(d?.toLocaleString());
  //       _setLastUpdatedProductDate(d?.toLocaleString());
  //     }
  //   }
  // }, [changedProducts, id]);

  // permission based columns for the table
  React.useEffect(() => {
    let filteredTableColumns = [...productsTableConfig];

    if (permissionChecker("see-retail-price"))
      filteredTableColumns.push({
        heading: "Retail",
        attribute: "retail_price",
        type: "currency",
        sortable: true,
      });
    if (permissionChecker("see-cash-price"))
      filteredTableColumns.push({
        heading: "Cash",
        attribute: "cash_price",
        type: "currency",
        sortable: true,
      });
    if (permissionChecker("see-best-cash-price"))
      filteredTableColumns.push({
        heading: "Best Cash",
        attribute: "best_cash_price",
        type: "currency",
        sortable: true,
      });
    if (permissionChecker("see-wholesale-price"))
      filteredTableColumns.push({
        heading: "Special Wholesale",
        attribute: "special_wholesale_price",
        type: "currency",
        sortable: true,
      });
    if (permissionChecker("see-your-price"))
      filteredTableColumns.push({
        heading: "Your Price",
        attribute: "your_price",
        type: "currency",
        sortable: true,
      });

    filteredTableColumns.push({
      heading: "Last Updated",
      attribute: "updated_at",
      type: "datetime",
      sortable: true,
    });

    setFilteredProductColumns(filteredTableColumns);
  }, [permissionChecker]);

  const exportDataAsPDF = () => {
    console.log("Exporting data as pdf...");
    setPrintModalOpen(true);
    // fetchAllProductsToPrint();
  };

  // const fetchAllProductsToPrint = () => {
  //     setLoadingPrintModal(true);

  //     productsApi
  //         .getAllResources({ token: session.token, query: { category: id } })
  //         .then((response) => setProductsToPrint(response.data.data))
  //         .finally(() => setLoadingPrintModal(false));
  // };

  const onSubcategoryClick = (row: ICategory) => {
    if (active?._id === row?._id) {
      setActive({});
    } else {
      setActive(row);
    }

    if (selectedSubcategory === row?._id) {
      setSelectedSubcategory(undefined);
    } else {
      setSelectedSubcategory(row?._id);
    }
  };

  const onSubcategoryPageChange = (e: any, value: any) => {
    setPage(value);
  };

  const onSubcategoryRowsChange = (e: { target: { value: string } }) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  };

  const onProductClick = (row: IProduct) => {
    setSelectedProduct(row?._id);
    setModal(true);
  };

  const onProductPageChange = (e: any, value: any) => {
    setProductPage(value);
  };

  const onProductRowsChange = (e: { target: { value: string } }) => {
    setProductsRowsPerPage(parseInt(e.target.value));
    setProductPage(0);
  };

  const onProductsSelect = (ids: string[]) => {
    setProductSelectedRowsIds(ids);

    // get the products objects
    const newSelectedProducts =
      products.data?.data?.filter((p) => ids.includes(p?._id)) || [];

    // merge with previously selected;
    const merged = [...newSelectedProducts, ...productSelectedRows]
      // remove duplicates
      .filter((v, i, a) => a.findIndex((v2) => v2?._id === v?._id) === i);

    // set the selected products while filtering out the unselected
    setProductSelectedRows(merged.filter((p) => ids.includes(p?._id)));
  };

  // const onColumnSort = (c: any) => {
  //   if (columnSort === c) {
  //     setColumnSortDir(columnSortDir === "asc" ? "desc" : "asc");
  //   } else {
  //     setColumnSort(c);
  //     setColumnSortDir("asc");
  //   }
  // };

  // // fetch products the were recently updated
  // const fetchUpdated = () => {
  //     productsApi
  //         .getSpecificResource('updated', {
  //             query: {
  //                 attribute: "price_updated",
  //                 from: dayjs().subtract(1, 'day').startOf("day").unix() * 1000,
  //                 to: dayjs().unix() * 1000,
  //                 category: id,
  //             }
  //         })
  //         .then((res) => {
  //             if (res.data?.data?.length) {
  //                 const sorted = res.data?.data?.sort((a, b) => a.price_updated - b.price_updated).reverse()
  //                 const d = new Date(sorted[res.data?.data.length - 1].price_updated);
  //                 setLastUpdatedProductDate(d?.toLocaleDateString())
  //                 setPriceUpdatedProducts(sorted)
  //             } else {
  //                 setLastUpdatedProductDate('')
  //             }
  //         })
  // };

  // const getProductTableRowProps = useCallback(
  //   (row: IProduct, index: number) => {
  //     // (row, index) => (
  //     //     row['updated_at'] && isAfter(new Date(row['updated_at']), past1Minutes)
  //     //         ? "updated-now "
  //     //         : row['updated_at'] && isAfter(new Date(row['updated_at']), yesterday)
  //     //             ? "updated "
  //     //             : ""
  //     // )

  //     const updated_at = new Date(row["updated_at"] || 0)?.getTime() || 0;

  //     if (isBefore(updated_at, yesterday)) {
  //       return {};
  //     }

  //     let freshness = Math.abs((now - updated_at) / (MS_1_MINUTES * 0.8));

  //     freshness = Math.pow(freshness, 1);

  //     freshness = Math.min(1, Math.max(0, freshness));

  //     const fromColor = {
  //       hue: 7,
  //       saturation: 100,
  //       lightness: 59,
  //       alpha: 0.5,
  //     };

  //     const toColor = {
  //       hue: 80,
  //       saturation: 100,
  //       lightness: 62,
  //       alpha: 0.15,
  //     };

  //     const hue = fromColor.hue + (toColor.hue - fromColor.hue) * freshness;
  //     const saturation =
  //       fromColor.saturation +
  //       (toColor.saturation - fromColor.saturation) * freshness;
  //     const lightness =
  //       fromColor.lightness +
  //       (toColor.lightness - fromColor.lightness) * freshness;
  //     const alpha =
  //       fromColor.alpha + (toColor.alpha - fromColor.alpha) * freshness;

  //     return {
  //       sx: {
  //         // color: "white",
  //         backgroundColor: `hsla(${hue.toFixed(0)}deg, ${saturation.toFixed(
  //           0
  //         )}%, ${lightness.toFixed(0)}%, ${alpha.toFixed(3)}) !important`,
  //         transition: "background-color 1s !important",
  //       },
  //     };
  //   },
  //   [past1Minutes]
  // );

  const TR_MS_1_MINUTES = 60 * 1000;
  const TR_now = new Date().getTime();
  const TR_past1Minutes = new Date(TR_now - TR_MS_1_MINUTES);
  const TR_yesterday = new Date(TR_now - 24 * 60 * 60 * 1000);

  const getProductTableRowProps = useCallback(
    (row, index) => {
      const updated_at = new Date(row["updated_at"] || 0)?.getTime() || 0;

      if (isBefore(updated_at, TR_yesterday)) {
        return {};
      }

      let freshness = Math.abs((TR_now - updated_at) / (TR_MS_1_MINUTES * 0.8));
      freshness = Math.pow(freshness, 1);
      freshness = Math.min(1, Math.max(0, freshness));

      const fromColor = {
        hue: 7,
        saturation: 100,
        lightness: 59,
        alpha: 0.5,
      };

      const toColor = {
        hue: 80,
        saturation: 100,
        lightness: 62,
        alpha: 0.15,
      };

      const hue = fromColor.hue + (toColor.hue - fromColor.hue) * freshness;
      const saturation =
        fromColor.saturation +
        (toColor.saturation - fromColor.saturation) * freshness;
      const lightness =
        fromColor.lightness +
        (toColor.lightness - fromColor.lightness) * freshness;
      const alpha =
        fromColor.alpha + (toColor.alpha - fromColor.alpha) * freshness;

      return {
        style: {
          backgroundColor: `hsla(${hue.toFixed(0)}deg, ${saturation.toFixed(
            0
          )}%, ${lightness.toFixed(0)}%, ${alpha.toFixed(3)})`,
          transition: "background-color 1s",
        },
      };
    },
    [TR_past1Minutes]
  );

  const triggerModal = () => {
    setModal(true);
    setSelectedProduct(undefined);
  };

  const handleClose = () => {
    setModal(false);
  };

  const deleteProducts = () => {
    const payload = {
      token: session.token,
      data: productSelectedRowsIds,
    };
    deleteProductCallback.request(payload).then(() => {
      onProductsSelect([]);
    });
  };

  // React.useEffect(() => fetchUpdated(), [id]);

  const handleSearch = (value: string) => {
    setQ(value);
    setProductPage(0);
  };

  const headerStyle = {
    paddingRight: "40px",
    color: "#a09b91",
    fontWeight: "500",
    cursor: "pointer",
    transition: "color 0.3s",
    display: "flex",
    alignItems: "center",
  };

  const activeHeaderStyle = {
    ...headerStyle,
    color: "#000",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <Modal onClose={handleClose} size="medium" state={modal}>
        <AddEditViewProduct
          setModal={setModal}
          modal={modal}
          id={selectedProduct}
          refreshData={products.refresh}
        />
      </Modal>

      <GenericHeader
        onSearch={handleSearch}
        addResourceHandler={
          permissionChecker("create-product") ? triggerModal : undefined
        }
        deleteResourceHandler={
          permissionChecker("delete-product") ? deleteProducts : undefined
        }
        selectedRows={productSelectedRowsIds}
        model="product"
        customButtonsLabels={[
          "Export Data as PDF 📃",
          `Shopping Cart (${
            shoppingCart.rows?.filter((r) => !!r.product).length || 0
          }) 🛒`,
        ]}
        customButtonsHandlers={[
          () => exportDataAsPDF(),
          () => shoppingCart.openCart(),
        ]}
      />

      <div className="m-4 h-100">
        <Button
          ref={categoryButtonRef}
          onClick={() => setShowCategoriesMenu(true)}
          className={"mb-4"}
          endIcon={<FontAwesomeIcon icon={faChevronDown} />}
        >
          <Typography color="primary" variant="h1">
            {category?.data?.data[0]?.name}
          </Typography>
        </Button>
        <Menu
          anchorEl={categoryButtonRef.current}
          open={showCategoriesMenu}
          onClose={() => setShowCategoriesMenu(false)}
          variant="menu"
        >
          {categories.data?.data?.map((c) => (
            <MenuItem
              key={c?._id}
              // @ts-ignore
              component={NavLink}
              // @ts-ignore
              to={c?._id}
              // @ts-ignore
              replace
              sx={{
                "&.active": {
                  color: "white",
                },
              }}
            >
              {c.name}
            </MenuItem>
          ))}
        </Menu>
        <ChangedProductsSection
        // lastChanged={lastUpdatedProductDate}
        // onDismiss={() => setLastUpdatedProductDate("")}
        // changedProducts={changedProducts}
        />
        <Grid container spacing={2} className="h-100">
          <Grid
            item
            xl={collapsed ? 1 : 4}
            lg={collapsed ? 1 : 4}
            md={collapsed ? 1 : 4}
            sm={collapsed ? 1 : 4}
            xs={12}
            className="h-100"
            style={{ transition: "0.3s all" }}
          >
            <Card className="h-100 overflow-auto">
              {collapsed ? (
                <div className="d-flex justify-content-center p-4">
                  <CustomButton
                    className="iconButton"
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    <FontAwesomeIcon
                      icon={faAngleDoubleRight as IconProp}
                      className="icon"
                    />
                  </CustomButton>
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-between p-4">
                    <Typography
                      variant="h4"
                      color="textSecondary"
                      style={{ fontSize: "20px" }}
                    >
                      Subcategories
                    </Typography>
                    <CustomButton
                      className="iconButton"
                      onClick={() => setCollapsed(!collapsed)}
                      style={{ padding: "0px 5px" }}
                    >
                      <FontAwesomeIcon
                        icon={faAngleDoubleLeft as IconProp}
                        className="icon"
                      />
                    </CustomButton>
                  </div>

                  <Box
                    sx={{
                      overflowX: "scroll",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                    className="usersList"
                  >
                    {/* <div style={{ overflowX: 'scroll', paddingLeft: '15px' }}> */}

                    <table style={{ width: "100%", marginBottom: "15px" }}>
                      <thead>
                        <tr>
                          <th style={{ color: "#a09b91", fontWeight: "500" }}>
                            Name
                          </th>
                          <th style={{ color: "#a09b91", fontWeight: "500" }}>
                            Creation Date
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {category?.data?.data[0]?.subcategories
                          ?.slice(subCatstartIndex, subCatEndIndex)
                          // category?.data?.data[0]?.subcategories
                          // ?.slice(
                          //   page * rowsPerPage,
                          //   page * rowsPerPage + rowsPerPage
                          // )
                          .map((row: ICategory, index: number) => (
                            <tr
                              style={{
                                height: "60px",
                                borderBottom: "2px solid rgb(228 228 228)",
                                cursor: "pointer",
                                backgroundColor:
                                  active?._id === row?._id
                                    ? "rgb(242 242 242)"
                                    : "",
                              }}
                              key={row?._id}
                              className={
                                active === row
                                  ? " activeRow "
                                  : "" + " customRow"
                              }
                              onClick={() => {
                                onSubcategoryClick(row);
                              }}
                            >
                              <td
                                style={{
                                  color: "#5b5b68",
                                  paddingRight: "25px",
                                }}
                              >
                                {row.name ? row.name : "none"}
                              </td>
                              <td style={{ color: "#5b5b68" }}>
                                {row.created_at
                                  ? dayjs(row.created_at).format("MM/DD/YYYY")
                                  : "none"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginBottom: "15px" }}>
                        <label
                          htmlFor="subCatItemsPerPage"
                          style={{ color: "#5b5b68", marginRight: "10px" }}
                        >
                          Rows per page:
                        </label>
                        <select
                          style={{
                            marginTop: "15px",
                            marginRight: "10px",
                            border: "none",
                            outline: "none",
                          }}
                          id="subCatItemsPerPage"
                          value={subCatItemsPerPage}
                          onChange={handleSubCatItemsPerPageChange}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                      <button
                        style={{
                          color: "#5b5b68",
                          background: "none",
                          border: "none",
                        }}
                        onClick={() =>
                          handleSubCatPageChange(subCatCurrentPage - 1)
                        }
                        disabled={subCatCurrentPage === 1}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </button>
                      <span
                        style={{
                          color: "#5b5b68",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {subCatCurrentPage} / {subCatTotalPages}
                      </span>
                      <button
                        style={{
                          color: "#5b5b68",
                          background: "none",
                          border: "none",
                        }}
                        onClick={() =>
                          handleSubCatPageChange(subCatCurrentPage + 1)
                        }
                        disabled={subCatCurrentPage === subCatTotalPages}
                      >
                        <FontAwesomeIcon icon={faArrowRight} />
                      </button>
                    </div>
                    {/* </div> */}

                    {/* <TableContainer className="tableContainer">
                      <Table className="usersListTable">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Creation Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {category?.data?.data[0]?.subcategories
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row: ICategory, index: number) => (
                              <TableRow
                                key={row?._id}
                                className={
                                  active === row
                                    ? " activeRow "
                                    : "" + " customRow"
                                }
                                onClick={() => {
                                  onSubcategoryClick(row);
                                }}
                              >
                                <TableCell>
                                  {row.name ? row.name : "none"}
                                </TableCell>
                                <TableCell>
                                  {row.created_at
                                    ? dayjs(row.created_at).format("MM/DD/YYYY")
                                    : "none"}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer> */}
                    {/* <TablePagination
                      className="pagination"
                      component="div"
                      page={page}
                      rowsPerPage={rowsPerPage}
                      count={
                        category?.data?.data[0]?.subcategories?.length || 0
                      }
                      onPageChange={onSubcategoryPageChange}
                      onRowsPerPageChange={onSubcategoryRowsChange}
                    /> */}
                  </Box>
                </>
              )}
            </Card>
          </Grid>
          <Grid
            item
            xl={collapsed ? 11 : 8}
            lg={collapsed ? 11 : 8}
            md={collapsed ? 11 : 8}
            sm={collapsed ? 11 : 8}
            xs={12}
            className="h-100"
            style={{ transition: "0.3s all" }}
          >
            <Dialog
              open={showSelected}
              onClose={() => setShowSelected(false)}
              fullWidth
            >
              <DialogTitle>Selected Products</DialogTitle>
              <DialogContent>
                <List>
                  <ListItem
                    secondaryAction={
                      <Button
                        onClick={() => {
                          setShowSelected(false);
                          onProductsSelect([]);
                        }}
                      >
                        ❌
                      </Button>
                    }
                  >
                    <TextField
                      sx={{
                        marginInlineEnd: 4,
                      }}
                      label={"Search"}
                      type="search"
                      fullWidth
                      size={"small"}
                      InputProps={{
                        startAdornment: "🔎",
                      }}
                      value={selectedQuery}
                      onChange={(e) => setSelectedQuery(e.currentTarget.value)}
                    />
                  </ListItem>
                  {productSelectedRows
                    .filter((p) => {
                      if (selectedQuery) {
                        return selectedQueryRegex.test(
                          [
                            p.model_number,
                            p.description,
                            p.category?.name || "",
                            p.subcategory?.name || "",
                          ].join(" ")
                        );
                      }
                      return true;
                    })
                    .map((product, index) => (
                      <ListItem
                        key={product?._id}
                        secondaryAction={
                          <Button
                            onClick={() => {
                              productSelectedRowsIds?.splice(index, 1);
                              onProductsSelect(productSelectedRowsIds.concat());
                            }}
                          >
                            ❌
                          </Button>
                        }
                      >
                        <ListItemText
                          sx={{
                            marginInlineEnd: 4,
                          }}
                          primary={
                            <>
                              ({product.model_number}) {product.description}
                            </>
                          }
                          secondary={
                            <>
                              {product.category?.name}{" "}
                              {product.subcategory &&
                                ` | ${product.subcategory?.name}`}
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                </List>
              </DialogContent>
            </Dialog>
            <Card className="h-100">
              <div className="p-4">
                <Typography
                  variant="h4"
                  color="textSecondary"
                  style={{ fontSize: "20px" }}
                >
                  Products{" "}
                  <Button
                    variant="contained"
                    size={"small"}
                    disabled={!productSelectedRows.length}
                    onClick={() => {
                      shoppingCart.addToCart(
                        productSelectedRows.map((product) => ({ product }))
                      );
                      onProductsSelect([]);
                    }}
                  >
                    Add selected to Cart
                  </Button>
                  {productSelectedRows.length ? (
                    <Button
                      size={"large"}
                      title="Show Selected"
                      onClick={() => setShowSelected(true)}
                    >
                      ({productSelectedRows.length} Selected)
                    </Button>
                  ) : (
                    ""
                  )}
                </Typography>
              </div>

              <div>
                {deleteProductCallback.loading || products.loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      color: "#2367e7",
                    }}
                  >
                    <CircularProgress style={{ color: "#2367e7" }} />
                  </div>
                ) : (
                  <div style={{ overflowX: "scroll" }}>
                    {filteredProducts.length > 0 ? (
                      <>
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  paddingRight: "40px",
                                  color: "#a09b91",
                                }}
                              >
                                <Checkbox
                                  color="primary"
                                  onChange={(e) => selectAll(e.target.checked)}
                                  checked={isSelectedAll}
                                />
                              </th>
                              {filteredProductColumns.map((row, index) => {
                                return (
                                  <th
                                    style={{
                                      paddingRight: "30px",
                                      width: "400px",
                                      color:
                                        sortConfig.key === row.attribute
                                          ? activeHeaderStyle.color
                                          : headerStyle.color,
                                      fontWeight: "500",
                                      cursor: "pointer",
                                    }}
                                    key={row.attribute || index}
                                    onClick={() => handleSort(row.attribute)}
                                  >
                                    {row.heading}
                                    <span style={{ marginLeft: "5px" }}>
                                      {sortConfig.key === row.attribute
                                        ? sortConfig.direction === "ascending"
                                          ? "↑"
                                          : "↓"
                                        : "↑↓"}
                                    </span>
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {sortedItems.map((row, index) => {
                              const rowProps = getProductTableRowProps(
                                row,
                                index
                              );

                              return (
                                <tr
                                  // onClick={() => onProductClick(row)}
                                  style={{
                                    height: "100px",
                                    borderBottom: "2px solid rgb(228 228 228)",
                                    cursor: "pointer",
                                    ...rowProps.style,
                                  }}
                                  key={row._id || index}
                                >
                                  <td>
                                    <Checkbox
                                      color="primary"
                                      value={row._id}
                                      checked={productSelectedRowsIds.includes(
                                        row._id
                                      )}
                                      onChange={(e) =>
                                        handleSelectionChange(row._id)
                                      }
                                    />
                                  </td>
                                  {filteredProductColumns.map(
                                    (object, index) => {
                                      return (
                                        <td
                                          onClick={() => onProductClick(row)}
                                          style={{
                                            color: "#5b5b68",
                                            paddingRight: "12px",
                                          }}
                                          key={
                                            (row._id || index) +
                                            object.attribute
                                          }
                                        >
                                          {/* if column is date, display formatted date */}
                                          {object.type === "date" &&
                                            (dayjs(
                                              row[object.attribute]
                                            ).isValid() &&
                                            dayjs(
                                              row[object.attribute]
                                            ).isAfter(0)
                                              ? dayjs(
                                                  row[object.attribute]
                                                ).format("MM/DD/YYYY")
                                              : "none")}
                                          {object.type === "datetime" &&
                                            (dayjs(
                                              row[object.attribute]
                                            ).isValid() &&
                                            dayjs(
                                              row[object.attribute]
                                            ).isAfter(0)
                                              ? dayjs(
                                                  row[object.attribute]
                                                ).format("MM/DD/YYYY hh:mm a")
                                              : "--")}
                                          {/*  */}
                                          {object.type === "boolean" &&
                                            (row[object.attribute] == true ? (
                                              <FontAwesomeIcon
                                                icon={faCheck as IconProp}
                                              />
                                            ) : (
                                              <FontAwesomeIcon
                                                icon={faTimes as IconProp}
                                              />
                                            ))}
                                          {(object.type === "string" ||
                                            object.type === "number") &&
                                            (row[object.attribute]
                                              ? row[object.attribute]
                                              : "none")}
                                          {object.type == "currency" &&
                                            (row[object.attribute]
                                              ? "€ " + row[object.attribute]
                                              : "none")}
                                          {object.type == "link" &&
                                            (row[object.attribute] ? (
                                              <a
                                                href={row[object.attribute]}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faFilePdf as IconProp}
                                                  className="fontAwesomeIcon"
                                                />
                                              </a>
                                            ) : (
                                              "none"
                                            ))}
                                          {object.type == "files_array" &&
                                            renderFiles(row, object)}
                                        </td>
                                      );
                                    }
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        <div
                          style={{
                            paddingLeft: "15px",
                            display: "flex",
                            alignItems: "center",
                            columnGap: "20px",
                            marginTop: "10px",
                          }}
                        >
                          <span>Total Items: {filteredProducts.length}</span>
                          <div style={{ marginBottom: "15px" }}>
                            <label
                              htmlFor="itemsPerPage"
                              style={{ color: "#5b5b68" }}
                            >
                              Rows per page:
                            </label>
                            <select
                              style={{
                                marginTop: "15px",
                                marginRight: "10px",
                                border: "none",
                                outline: "none",
                              }}
                              id="itemsPerPage"
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>
                          <div>
                            <button
                              style={{
                                color: "#5b5b68",
                                background: "none",
                                border: "none",
                              }}
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                            <span
                              style={{
                                color: "#5b5b68",
                                marginRight: "10px",
                                marginLeft: "10px",
                              }}
                            >
                              {currentPage} / {totalPages}
                            </span>
                            <button
                              style={{
                                color: "#5b5b68",
                                background: "none",
                                border: "none",
                              }}
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            >
                              <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <NoResourceAvailable
                        resource="product"
                        image={placeholderPic}
                        addButtonLine={true}
                      />
                    )}
                  </div>
                )}
              </div>

              {/* <GenericTable
                list={filteredProducts}
                totalCount={filteredProducts.length || 0}
                onSelectedChange={onProductsSelect}
                selectedRows={productSelectedRowsIds}
                page={productPage}
                numberOfData={filteredProducts.length || 0}
                loading={deleteProductCallback.loading || products.loading}
                rowsPerPage={productsRowsPerPage}
                onRowsChange={onProductRowsChange}
                onPageChange={onProductPageChange}
                image={placeholderPic}
                tableConfig={filteredProductColumns}
                model="product"
                passRow={onProductClick}
                frontendPagination
                columnSort={columnSort}
                columnSortDir={columnSortDir}
                onColumnSort={onColumnSort}
                getRowProps={getProductTableRowProps}
              /> */}
            </Card>
          </Grid>
        </Grid>
      </div>

      <ProductPrintModal
        state={printModalOpen}
        onClose={setPrintModalOpen}
        loading={products.loading}
        products={products.data?.data || []}
      />
    </Box>
  );
};

export default ProductPriceCategory;
