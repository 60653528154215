import React from "react";
import "./ErrorAlert.scss";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import useStoreAlertsSelector from "../../../hooks/useStoreAlertsSelector";
import { useDispatch } from "react-redux";
import { hideErrorAlert } from "../../../store/actions/alerts";
import Alert from "@mui/lab/Alert";
import AlertTitle from "@mui/lab/AlertTitle";

const ErrorAlert = () => {
  const storeAlerts = useStoreAlertsSelector();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideErrorAlert());
  };

  return (
    <>
      <Snackbar
        TransitionComponent={Slide}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        className="errorAlert"
        onClose={handleClose}
        open={storeAlerts?.error?.open}
      >
        <Alert onClose={handleClose} severity="error">
          <AlertTitle>Error!</AlertTitle>
          {storeAlerts?.error?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ErrorAlert;
