import React from "react";
import { Route, Redirect } from "react-router-dom";

interface IProps {
  component: any;
  token: string;
  exact: boolean;
  path: string;
}

const ProtectedRoute = ({ component: Component, token, ...rest }: IProps) => {
  console.log("Trying to access protected route... ");

  return (
    <Route
      {...rest}
      render={(props) => {
        return token ? <Component {...props} /> : <Redirect to="/login" />;
      }}
    />
  );
};

export default ProtectedRoute;
