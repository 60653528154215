import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmDialog = (props: any) => {
  const { setConfirmConfig, confirmConfig, executeActionCallback } = props;

  const handleClose = () => {
    setConfirmConfig({
      ...confirmConfig,
      visible: false,
    });
  };

  const triggerCancelCallback = () => {
    handleClose();
  };

  const triggerActionCallback = () => {
    handleClose();
    executeActionCallback();
  };

  return (
    <Dialog onClose={handleClose} open={confirmConfig.visible}>
      <DialogTitle>{confirmConfig.title}</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">
          {confirmConfig.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="secondary" onClick={triggerCancelCallback}>
          Cancel
        </Button>
        <Button color="secondary" onClick={triggerActionCallback}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmDialog };
