import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import useStoreSessionSelector from "hooks/useStoreSessionSelector";
import { SKIP, useApiHook } from "providers/ApiProvider";
import { useCallback, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { IProduct } from "../../interfaces/Products";
import { useShoppingCart } from "./context";
import { StyledShoppingCartInvoice } from "./styles";
import { IShoppingCartProduct } from "./types";

export default function ShoppingCartModal({
  onClose,
  open,
  ...props
}: DialogProps) {
  const {
    rows,
    setRows,
    tax,
    setTax,
    discount,
    setDiscount,
    shipping,
    setShipping,
    getBestTotal,
    getRetailTotal,
    addToCart,
    reset,
  } = useShoppingCart();

  const getEditableContentProps = useCallback((content: string) => {
    return {
      contentEditable: true,
      dangerouslySetInnerHTML: {
        __html:
          sessionStorage.getItem(`shoppingCartContent:${content}`) || content,
      },
      onInput: (e: any) => {
        sessionStorage.setItem(
          `shoppingCartContent:${content}`,
          e.target.innerHTML
        );
      },
    };
  }, []);

  const getInputProps = useCallback((name: string, defaultValue = "") => {
    return {
      defaultValue:
        sessionStorage.getItem(`shoppingCartInput:${name}`) ?? defaultValue,
      onInput: (e: any) => {
        sessionStorage.setItem(`shoppingCartInput:${name}`, e.target.innerHTML);
      },
    };
  }, []);

  const printComponentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    //@ts-ignore
    content: () => printComponentRef.current,
  });

  function addRow() {
    addToCart([{}]);
  }

  const session = useStoreSessionSelector();
  const productsPage = useApiHook(
    "Products",
    "getAllResources",
    session?.token ? {} : SKIP
  );

  const products = useMemo(() => {
    return productsPage.data?.data || [];
  }, [productsPage]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth {...props}>
      <DialogTitle variant="h2">Shopping Cart</DialogTitle>
      <Divider />
      <DialogContent>
        <Button type="button" variant="contained" onClick={handlePrint}>
          Print
        </Button>{" "}
        <Button type="button" variant="outlined" onClick={reset}>
          Reset
        </Button>
      </DialogContent>
      <Divider />
      <DialogContent ref={printComponentRef}>
        <StyledShoppingCartInvoice>
          <Box
            gridColumn={"span 2"}
            gridRow={"span 2"}
            display={"flex"}
            gap={"1em"}
            alignItems={"center"}
            flexWrap={"wrap"}
          >
            <img
              src="/LogoDarkBlue.svg"
              alt=""
              style={{ maxWidth: "100%", height: "10em" }}
            />
            {/* <h2 >Ventura</h2> */}
            <Box flex={"1 0 100%"} />
          </Box>
          <Box gridColumn={"span 2"} justifySelf={"end"}>
            <h1 {...getEditableContentProps("Quotation")} />
          </Box>
          <Box />
          <Box gridColumn={"span 1"} justifySelf={"end"}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">#</InputAdornment>
                ),
              }}
              size="small"
              {...getInputProps("number", "1")}
            />
          </Box>
          <Box
            gridColumn={"span 2"}
            display="grid"
            gridTemplateColumns={"1fr 1fr"}
            gap={"0.5em"}
          >
            <Box className="hide-on-empty">
              <small>Client</small>
              <TextField
                {...getInputProps("client")}
                required
                fullWidth
                multiline
                minRows={2}
              />
            </Box>
            <Box className="hide-on-empty">
              <small>Sales Representative</small>
              <TextField
                {...getInputProps("Sales Representative")}
                required
                fullWidth
                multiline
                minRows={2}
              />
            </Box>
          </Box>
          <Box
            gridColumn={"span 2"}
            justifySelf={"end"}
            gridTemplateColumns={"1fr 0.9fr"}
            gap={"0.5em"}
            alignItems={"center"}
            sx={{
              display: "grid !important",
              "& > :nth-child(odd)": {
                justifySelf: "end",
              },
              ".MuiTextField-root:has(input:invalid)": {
                scale: 0.5,
              },
            }}
            dir={"rtl"}
            className="hide-on-empty"
          >
            <TextField
              {...getInputProps("Date")}
              fullWidth
              required
              type="date"
              size="small"
            />
            <label {...getEditableContentProps("Date")} />
            {/* <TextField fullWidth required size="small" />
                    <label >Payment Terms</label> */}
            {/* <TextField fullWidth required type="date" size="small" />
                    <label >Due Date</label> */}
            {/* <TextField fullWidth required size="small" /> */}
            {/* <label >PO Number</label> */}
          </Box>

          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Qty.</TableCell>
                <TableCell>Retail Price</TableCell>
                <TableCell>Best Price</TableCell>
                <TableCell
                  padding="checkbox"
                  className="delete-column hide-on-print"
                >
                  <IconButton
                    onClick={() => setRows([])}
                    className="remove-button hide-on-print"
                    size="small"
                  >
                    ❌
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index, collection) => (
                <QuoteProductRow
                  row={row}
                  key={row?._id}
                  onRemove={() =>
                    setRows((rs) => [...rs.filter((r) => r._id !== row?._id)])
                  }
                  onChange={(r) => {
                    rows[index] = r;
                    setRows([...rows]);
                    // console.log({ Changed: r })
                  }}
                  products={products}
                  loadingProducts={productsPage.loading}
                />
              ))}
            </TableBody>
            <tfoot>
              <tr>
                <TableCell
                  className="hide-on-print"
                  padding="checkbox"
                  style={{ border: 0 }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => addRow()}
                  >
                    Add row
                  </Button>
                </TableCell>
              </tr>
              <tr className="hide-on-screen">
                <td>&nbsp;</td>
              </tr>
              <tr className="hide-on-screen">
                <td>&nbsp;</td>
              </tr>
            </tfoot>
          </Table>
          <Box gridColumn={"span 2"}>
            <Box className="hide-on-empty">
              <small>Notes</small>
              <TextField
                {...getInputProps("Notes")}
                required
                fullWidth
                multiline
                minRows={2}
              />
            </Box>
            <Box className="hide-on-empty">
              <small>Terms</small>
              <TextField
                // disabled
                required
                fullWidth
                multiline
                minRows={2}
                value={`QUOTATION VALID UNTIL STOCK LASTS`}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
          <Box
            gridColumn={"span 2"}
            textAlign={"center"}
            width={"90%"}
            justifySelf={"end"}
            alignSelf={"start"}
          >
            <table className="table hide-row-on-empty" style={{ margin: 0 }}>
              <tr>
                <td {...getEditableContentProps("Retail Total")} />
                <td>€{getRetailTotal()?.toFixed(2)}</td>
              </tr>
              <tr>
                <td {...getEditableContentProps("Best Price")} />
                <td>€{getBestTotal()?.toFixed(2)}</td>
              </tr>
            </table>
          </Box>
        </StyledShoppingCartInvoice>
      </DialogContent>
    </Dialog>
  );
}

function QuoteProductRow({
  row,
  onRemove,
  onChange,
  products,
  loadingProducts,
}: {
  row?: IShoppingCartProduct;
  onRemove?: Function;
  onChange?: (row: IShoppingCartProduct) => any;
  products?: IProduct[];
  loadingProducts?: boolean;
}) {
  const [q, setQ] = useState("");
  const [product, setProduct] = useState<any>(row?.product);
  const [quantity, setQuantity] = useState<number>(row?.quantity || 1);
  const [bestPrice, setBestPrice] = useState<any>(row?.bestPrice);
  const [retailPrice, setRetailPrice] = useState<any>(row?.retailPrice);

  return (
    <>
      <TableRow>
        <TableCell padding="checkbox">
          <Autocomplete
            value={product}
            onChange={(e, v) => {
              setProduct(v);
              setBestPrice(v?.best_cash_price);
              setRetailPrice(v?.retail_price);
              onChange?.({
                ...row,
                product,
                bestPrice: v?.best_cash_price * quantity,
                retailPrice: v?.retail_price * quantity,
                quantity,
              });
            }}
            onInputChange={(e: any) => {
              setQ((e?.target as HTMLInputElement)?.value || "");
            }}
            clearOnBlur={false}
            noOptionsText={"No Result"}
            options={products || []}
            getOptionLabel={(option: any) =>
              option?.model_number
                ? `(${option.model_number}) ${option.description} ${
                    option.category?.name
                  } ${
                    option.subcategory ? ` | ${option.subcategory?.name}` : ""
                  }`
                : ""
            }
            renderOption={(props, option) => (
              <MenuItem
                {...props}
                style={{
                  ...props.style,
                  whiteSpace: "pre-wrap",
                }}
              >
                {option?.model_number
                  ? `(${option.model_number}) ${option.description} \n ${
                      option.category?.name
                    } ${
                      option.subcategory ? ` | ${option.subcategory?.name}` : ""
                    }`
                  : ""}
              </MenuItem>
            )}
            loading={loadingProducts}
            loadingText={<>Loading...</>}
            renderInput={(params) => (
              <TextField
                multiline
                variant="outlined"
                {...params}
                size="small"
                InputProps={{
                  ...params?.InputProps,
                  endAdornment: undefined,
                  startAdornment: <>{params?.InputProps?.startAdornment}</>,
                }}
                placeholder="---"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </TableCell>
        <TableCell padding="checkbox">
          <TextField
            type="number"
            size="small"
            value={quantity || ""}
            onChange={(e) => {
              const _quantity = (e.currentTarget as HTMLInputElement)
                .valueAsNumber;
              const _bestPrice =
                _quantity >= 0 && product?.best_cash_price >= 0
                  ? _quantity * product?.best_cash_price
                  : bestPrice;
              const _retailPrice =
                _quantity >= 0 && product?.retail_price >= 0
                  ? _quantity * product?.retail_price
                  : retailPrice;

              setQuantity(_quantity as any);
              setBestPrice(_bestPrice);
              setRetailPrice(_retailPrice);
              onChange?.({
                ...row,
                quantity: e.currentTarget.value as any,
                bestPrice: _bestPrice,
                retailPrice: _retailPrice,
              });
            }}
          />
        </TableCell>
        <TableCell padding="checkbox">
          <TextField
            type="number"
            InputProps={{ startAdornment: "€" }}
            size="small"
            value={retailPrice || ""}
            onChange={(e) => {
              setRetailPrice(e.currentTarget.value);
              onChange?.({ ...row, retailPrice: e.currentTarget.value as any });
            }}
          />
        </TableCell>
        <TableCell padding="checkbox">
          <TextField
            type="number"
            InputProps={{ startAdornment: "€" }}
            size="small"
            value={bestPrice || ""}
            onChange={(e) => {
              setBestPrice(e.currentTarget.value);
              onChange?.({ ...row, bestPrice: e.currentTarget.value as any });
            }}
          />
        </TableCell>
        <TableCell padding="checkbox" className="delete-column hide-on-print">
          <IconButton
            style={{
              pointerEvents: onRemove ? "auto" : "none",
              display: onRemove ? undefined : "none",
            }}
            onClick={() => onRemove?.()}
            className="remove-button hide-on-print"
            size="small"
          >
            ❌
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
