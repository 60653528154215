import { SKIP, useApiHook } from "providers/ApiProvider";
import { apiContext } from "providers/ApiProvider/context";
import { useContext, useEffect, useState } from "react";
import { register } from "serviceWorkerRegistration";
import { useApiHookCallback } from "../providers/ApiProvider";
import useStoreSessionSelector from "hooks/useStoreSessionSelector";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

const VAPID_PUBLIC =
  process.env.REACT_APP_VAPID_PUBLIC || window.REACT_APP_VAPID_PUBLIC;

const isNotificationAvailable = "Notification" in window;

// console.log({ VAPID_PUBLIC })

export const ApiWebPush = () => {
  const { onModelsUpdate, state, setState } = useContext(apiContext);
  const [firstRun, setFirstRun] = useState(true);
  const [worker, setWorker] = useState<ServiceWorkerRegistration>();
  const session = useStoreSessionSelector();
  const user = useApiHook(
    "Authentication",
    "getSpecificResource",
    session?.token ? "me" : SKIP,
    {}
  );
  const userCallback = useApiHookCallback("Authentication", "patchResource");
  const [subscribed, setSubscribed] = useState(false);
  const [notificationPermission, setNotificationPermission] =
    useState<(typeof Notification)["permission"]>("default");
  const [showNotificationRequest, setShowNotificationRequest] = useState(false);

  useEffect(() => {
    register({
      serviceWorkerPath: "/sw.js",
      onReady: (registration) => {
        setWorker(registration);
      },
      onSuccess: (registration) => {
        setWorker(registration);
      },
      onUpdate: (registration) => {
        registration.waiting?.postMessage({ type: "SKIP_WAITING" });
        setWorker(registration);
      },
    });
  }, []);

  const initWebPush = async () => {
    if (!worker) {
      return;
    }

    try {
      if (notificationPermission !== "granted") return;

      // if user is undefined then its assumed that they are not logged in
      if (!user?.data?.result) {
        return;
      }

      if (!VAPID_PUBLIC) {
        throw new Error(
          "VAPID Public key is not set, aborting webpush initialization"
        );
      }

      // force unsubscribe always, to prevent having the same subscription for different users;
      (await worker.pushManager.getSubscription())?.unsubscribe();

      const subscription = await worker.pushManager.subscribe({
        applicationServerKey: VAPID_PUBLIC,
        userVisibleOnly: true,
      });

      console.log("initWebPush =>", { VAPID_PUBLIC });
      await userCallback.request({
        id: "me",
        data: {
          web_push: subscription.toJSON(),
        },
      });

      setSubscribed(true);
    } catch (e) {
      setSubscribed(false);
      console.error(e);
    }
  };

  useEffect(() => {
    initWebPush();
  }, [user.data?.result?._id, worker, notificationPermission]);

  // Notification Permissions
  const requestNotifications = async () => {
    const permission = await Notification.requestPermission();
    setNotificationPermission(permission);
  };
  useEffect(() => {
    if (!user?.data?.result) return;
    if (!isNotificationAvailable) return;

    let cleanUp = () => {};
    navigator.permissions?.query({ name: "notifications" }).then((result) => {
      if (result.state === "granted") {
        setNotificationPermission("granted");
        return;
      }

      if (result.state === "prompt") {
        setNotificationPermission("default");
      } else {
        setNotificationPermission(result.state);
      }

      setShowNotificationRequest(true);

      const onChange: any = (event: { target: typeof result }) => {
        if (event.target.state === "prompt") {
          setNotificationPermission("default");
        } else {
          setNotificationPermission(event.target.state);
        }

        if (event.target.state === "granted") {
          setShowNotificationRequest(false);
        }
      };

      result.addEventListener("change", onChange);
      cleanUp = () => {
        result.removeEventListener("change", onChange);
      };

      requestNotifications();
    });

    return cleanUp;
  }, [user?.data?.result]);

  useEffect(() => {
    if (subscribed && "serviceWorker" in navigator) {
      const onMessage = (event) => {
        if (
          typeof event.data === "object" &&
          "type" in event.data &&
          event.data.type === "MODEL_CHANGE"
        ) {
          onModelsUpdate(event.data as any);
        }
      };

      const to = setTimeout(() => {}, 1000);
      navigator.serviceWorker.addEventListener("message", onMessage);
      return () => {
        navigator.serviceWorker.removeEventListener("message", onMessage);
        clearTimeout(to);
      };
    }
  }, [subscribed]);

  useEffect(() => {
    const onMessage = (event) => {
      if (
        typeof event.data === "object" &&
        "type" in event.data &&
        event.data.type === "GET_CACHE" &&
        event.data.cachedData !== null &&
        event.data.cachedData !== state
      ) {
        setState(event.data.cachedData);
      }
    };
    if (!firstRun) {
      worker?.active?.postMessage({
        type: "SET_CACHE",
        cachedData: state,
      });
    } else {
      setFirstRun(false);
    }
    worker?.active?.postMessage({ type: "GET_CACHE" });
    navigator.serviceWorker.removeEventListener("message", onMessage);
    return () => {
      navigator.serviceWorker.removeEventListener("message", onMessage);
    };
  }, [state, firstRun]);
  return (
    <>
      <Dialog
        open={showNotificationRequest}
        onClose={() => setShowNotificationRequest(false)}
      >
        <DialogTitle
          style={{
            textAlign: "center",
          }}
        >
          <Typography variant="h2" color="primary">
            Real Time Updates
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            textAlign: "center",
          }}
        >
          <p>
            Real time updates feature allows to get instantly get changes done
            by others.
          </p>
          <p>This feature needs notifications to be allowed.</p>
          <br />
          {notificationPermission === "default" && (
            <>
              <Typography variant="h3" color="error" textAlign={"center"}>
                Please make sure you allow notifications
              </Typography>
              <img
                src="/webpush/prompt.png"
                style={{
                  maxWidth: "100%",
                  display: "block",
                  margin: "1em auto",
                }}
              />

              <p
                style={{
                  textAlign: "center",
                }}
              >
                If the prompt in the picture above didn't appear{" "}
                <Button
                  variant="contained"
                  onClick={() => requestNotifications()}
                >
                  Click Here
                </Button>
              </p>
            </>
          )}

          {notificationPermission === "denied" && (
            <>
              <Typography
                variant="h3"
                fontSize={"1.25em"}
                color="error"
                textAlign={"center"}
              >
                Notifications are disabled, Please enable them!
              </Typography>
              <br />
              <p>
                Go to the site settings as shown in the pictures below and
                enable notifications.
              </p>
              <br />
              <Box
                display={"flex"}
                flexWrap="wrap"
                justifyContent={"center"}
                gap={"5px"}
              >
                <img
                  src="/webpush/denied_1.png"
                  style={{
                    maxWidth: "calc(50% - 5px)",
                    flex: "1 0 calc(50% - 5px)",
                    display: "inline-block",
                  }}
                />

                <img
                  src="/webpush/denied_2.png"
                  style={{
                    maxWidth: "calc(50% - 5px)",
                    flex: "1 0 calc(50% - 5px)",
                    display: "inline-block",
                  }}
                />

                <img
                  src="/webpush/denied_3.png"
                  style={{
                    maxWidth: "calc(100% - 5px)",
                    flex: "1 0 calc(100% - 5px)",
                    display: "inline-block",
                  }}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowNotificationRequest(false)}
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
