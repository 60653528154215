import Box from "@mui/material/Box";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import React, { useEffect, useMemo } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import ErrorAlert from "./components/Alerts/ErrorAlert/ErrorAlert";
import SuccessAlert from "./components/Alerts/SuccessAlert/SuccessAlert";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import ProtectedRoute from "./components/RouteComponents/ProtectedRoute";
import SessionRoute from "./components/RouteComponents/SessionRoute";
import Sidebar from "./components/Sidebar/Sidebar";
import { useFreshUserData } from "./hooks/apiHooks/useFreshUserData";
import useScreenSize from "./hooks/useScreenSize";
import useStoreSessionSelector from "./hooks/useStoreSessionSelector";
import { CategoriesFormView, CategoriesTableView } from "./pages/Categories";
import ProductPriceCategory from "./pages/ProductPriceCategory/ProductPriceCategory";
import ProductPrices from "./pages/ProductPrices/ProductPrices";
import AddEditUser from "./pages/Users/AddEditUser/AddEditUser";
import UsersTableView from "./pages/Users/UsersTableView/UsersTableView";
import Forgot from "./pages/forgot/Forgot";
import Login from "./pages/login/Login";
import Reset from "./pages/reset/Reset";
import theme from "./services/theme";
import { ShoppingCartProvider } from "./components/ShoppingCart/provider";
import { ApiWebPush } from "components/ApiWebPush";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { logout } from "store/actions/session";
import { showErrorAlert } from "store/actions/alerts";
import { useApiHook, SKIP } from "providers/ApiProvider";
import { addMinutes } from "date-fns";
declare global {
  interface Window {
    REACT_APP_API_URL: string;
    REACT_APP_STATIC_URL: string;
  }
}

const App = () => {
  // const activeUser: User = useUserData();
  // const { loading: loadingActiveUser, result: activeUser, error } = useFreshUserData();
  //
  const dispatch = useDispatch();
  const device = useScreenSize();
  // @ts-ignore
  const mainTheme = useMemo(() => createTheme(theme()), []);

  const session = useStoreSessionSelector();
  const [collapsed, setCollapsed] = React.useState(false);

  useEffect(() => {
    console.log(
      "ℹ Using API URL: ",
      process.env.REACT_APP_API_URL ??
        window.REACT_APP_API_URL ??
        "No API URL found, using prod url as fallback!"
    );
    console.log(
      "ℹ Using Static File URL: ",
      process.env.REACT_APP_STATIC_URL ??
        window.REACT_APP_STATIC_URL ??
        "No Static URL found, using prod url as fallback!"
    );
  }, []);

  const user = useApiHook(
    "Authentication",
    "getSpecificResource",
    session?.token ? "me" : SKIP,
    {}
  );
  const activeUser = user?.data?.result;

  // handling token expiration
  useEffect(() => {
    if (!session?.token) return;
    try {
      const decodedToken = jwtDecode<{
        sub: any;
        exp: number;
        iat: number;
        aud: string;
      }>(session.token);

      // admins timeout is 20 minutes & non-admins are 60 minutes
      const expirationTime = addMinutes(
        decodedToken.iat * 1000,
        decodedToken.aud === "admin" ? 20 : 60
      )?.getTime();

      const sessionDuration = expirationTime - Date.now();

      let timeout2;
      const timeout = setTimeout(() => {
        dispatch(
          showErrorAlert({
            message: "Your session has expired. \n Please Login again",
          })
        );
        dispatch(logout());

        // give time if the session state didn't update, if still not updated then force refresh the website
        timeout2 = setTimeout(() => {
          if (!window.location.href.includes("login")) {
            window.location.reload();
          }
        }, 3000);
      }, Math.max(0, sessionDuration));
      // console.log({decodedToken})
      return () => {
        clearTimeout(timeout);
        clearTimeout(timeout2);
      };
    } catch (e) {
      console.error({ decodedToken: e });
    }
  }, [session.token]);

  return (
    <>
      <Box>
        <ThemeProvider theme={mainTheme}>
          <ShoppingCartProvider>
            <ApiWebPush />
            <ErrorAlert />
            <SuccessAlert />
            <BrowserRouter>
              {session.token && (
                <>
                  {device !== "mobile" ? (
                    <div className="p-0" style={{ float: "left" }}>
                      {
                        <Box className="sideBox">
                          <Sidebar isSidebarCollapsed={setCollapsed} />
                        </Box>
                      }
                    </div>
                  ) : (
                    <MobileMenu />
                  )}
                </>
              )}

              <Box className="contentBox">
                <Switch>
                  {/* <ProtectedRoute path="/dashboard"
                    exact
                    token={session.token}
                    component={Dashboard} /> */}

                  <Redirect exact from="/" to="/productPrices/categories" />

                  <SessionRoute
                    exact
                    path="/login"
                    token={session.token}
                    component={Login}
                  />

                  <SessionRoute
                    exact
                    path="/forgot"
                    token={session.token}
                    component={Forgot}
                  />
                  <SessionRoute
                    exact
                    path="/reset"
                    token={session.token}
                    component={Reset}
                  />

                  {activeUser?.role == "admin" && (
                    <ProtectedRoute
                      exact
                      path="/users"
                      token={session.token}
                      component={UsersTableView}
                    />
                  )}

                  {activeUser?.role == "admin" && (
                    <ProtectedRoute
                      exact
                      path="/users/add"
                      token={session.token}
                      component={AddEditUser}
                    />
                  )}

                  {activeUser?.role == "admin" && (
                    <ProtectedRoute
                      exact
                      path="/users/:id"
                      token={session.token}
                      component={AddEditUser}
                    />
                  )}

                  <ProtectedRoute
                    exact
                    path="/categories"
                    token={session.token}
                    component={CategoriesTableView}
                  />

                  <ProtectedRoute
                    exact
                    path="/categories/add"
                    token={session.token}
                    component={CategoriesFormView}
                  />

                  <ProtectedRoute
                    exact
                    path="/categories/:id"
                    token={session.token}
                    component={CategoriesFormView}
                  />

                  <ProtectedRoute
                    exact
                    path="/productPrices/categories"
                    token={session.token}
                    component={ProductPrices}
                  />

                  <ProtectedRoute
                    exact
                    path="/productPrices/categories/:id"
                    token={session.token}
                    component={ProductPriceCategory}
                  />

                  {/* Whenever the path doesn't match with any route, redirect to home */}
                  <Route>
                    <Redirect to={"/"} />
                  </Route>
                </Switch>
              </Box>
            </BrowserRouter>
          </ShoppingCartProvider>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default App;
