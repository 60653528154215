import { ApiState } from "../types";

export default function mergeState(
  original: ApiState,
  updates: ApiState
): ApiState {
  original.responses = {
    ...original.responses,
    ...JSON.parse(JSON.stringify(updates.responses)),
  };

  for (const modelKey in updates.models) {
    const models = updates.models[modelKey];
    original.models[modelKey] ??= {};

    for (const key in models) {
      original.models[modelKey][key] = models[key];
    }
  }

  return original;
}
