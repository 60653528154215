import React from "react";
import { useRoles } from "../../../hooks/useRoles";

const UserRoles = ({ userRole, setUserRole }: any) => {
  const roles = useRoles();

  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center w-100 mt-4 mx-0 p-2">
        {roles.map((role, index) => (
          <React.Fragment key={role.value}>
            <div
              className={
                (userRole === role.value ? "roleSelected" : "roleUnselected") +
                " text-center col"
              }
              onClick={() => setUserRole(role.value)}
            >
              {role.label}
            </div>
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
};

export default UserRoles;
