import { TableConfig } from "../interfaces/tableConfig";

const productsTableConfig: TableConfig[] = [
  {
    heading: "Model",
    attribute: "model_number",
    type: "string",
    sortable: true,
  },
  {
    heading: "Available",
    attribute: "is_available",
    type: "boolean",
    sortable: true,
  },
  {
    heading: "Description",
    attribute: "description",
    type: "string",
    sortable: true,
  },

  { heading: "Files", attribute: "files", type: "files_array" },
  { heading: "Guarantee", attribute: "guarantee", type: "string" },
  // {heading: "Retail", attribute: "retail_price", type: "currency"},
  // {heading: "Cash", attribute: "cash_price", type: "currency"},
  // {heading: "Best Cash", attribute: "best_cash_price", type: "currency"},
  // {heading: "Special Wholesale", attribute: "special_wholesale_price", type: "currency"},
  // {heading: "Your Price", attribute: "your_price", type: "currency"},
];

export default productsTableConfig;
