import React from "react";
import LoginForm from "./LoginForm";

export interface LoginMobileInterface {
  setEmail: any;
  setPassword: any;
  handleRememberMe: any;
  login: any;
  loading: boolean;
}

const LoginMobile = ({
  setEmail,
  setPassword,
  handleRememberMe,
  login,
  loading,
}: LoginMobileInterface) => {
  return (
    <div className="container d-flex h-100">
      <div className="align-self-center col-12 py-5 px-3">
        <p>Welcome Back</p>
        <h3>Login to your account</h3>
        <LoginForm
          setEmail={setEmail}
          setPassword={setPassword}
          login={login}
          loading={loading}
          handleRememberMe={handleRememberMe}
        />
      </div>
    </div>
  );
};

export default LoginMobile;
