import { ApiState, OnModelsUpdateArguments } from "../types";

export default function onModelsUpdateHandler(args: {
  action: OnModelsUpdateArguments["action"];
  modelKey: OnModelsUpdateArguments["modelKey"];
  stateToUpdate: ApiState;
  updatedModelsMap: ApiState["models"][string];
}): ApiState {
  for (const key in args.stateToUpdate.responses) {
    args.stateToUpdate.responses[key].modified = true;
  }

  switch (args.action) {
    case "create":
      return onCreateModels(args);
    case "update":
      return onUpdateModels(args);
    case "delete":
      return onDeleteModels(args);
    default:
      return args.stateToUpdate;
  }
}

export const onCreateModels = (args: {
  modelKey: OnModelsUpdateArguments["modelKey"];
  stateToUpdate: ApiState;
  updatedModelsMap: ApiState["models"][string];
}): ApiState => {
  const responses: ApiState["responses"] = {};
  const models: ApiState["models"] = {
    [args.modelKey]: args.updatedModelsMap,
  };

  const modelIds = Object.keys(args.updatedModelsMap || {});
  const modelsArray = Object.values(args.updatedModelsMap || {});

  models[args.modelKey] ??= {};
  for (const key in args.updatedModelsMap) {
    models[args.modelKey][key] = args.updatedModelsMap[key];
  }

  console.log(modelsArray);

  for (const key in args.stateToUpdate.responses) {
    const response = args.stateToUpdate.responses[key];

    if (response.acceptsCreate) {
      response.modelIds = response.modelIds?.concat(modelIds) || modelIds;

      if (Array.isArray(response.response.data)) {
        response.response.data.unshift(...modelsArray);
      }
    }

    responses[key] = JSON.parse(JSON.stringify(response));
  }

  return {
    responses,
    models,
  };
};

export const onUpdateModels = (args: {
  modelKey: OnModelsUpdateArguments["modelKey"];
  stateToUpdate: ApiState;
  updatedModelsMap: ApiState["models"][string];
}): ApiState => {
  const responses: ApiState["responses"] = args.stateToUpdate.responses ?? {};
  const models: ApiState["models"] = args.stateToUpdate.models ?? {};

  models[args.modelKey] ??= {};
  for (const key in args.updatedModelsMap) {
    models[args.modelKey][key] = {
      ...models[key],
      ...args.updatedModelsMap[key],
    };
  }

  for (const key in args.stateToUpdate.responses) {
    const response = args.stateToUpdate.responses[key];

    if (Array.isArray(response.response.data)) {
      response.response.data = response.response.data.map((model) => ({
        ...model,
        ...models[args.modelKey][model._id],
      }));
    }
  }

  return {
    responses,
    models,
  };
};

export const onDeleteModels = (args: {
  modelKey: OnModelsUpdateArguments["modelKey"];
  stateToUpdate: ApiState;
  updatedModelsMap: ApiState["models"][string];
}): ApiState => {
  const responses: ApiState["responses"] = args.stateToUpdate.responses ?? {};
  const models: ApiState["models"] = args.stateToUpdate.models ?? {};
  const deletedModelIds = Object.keys(args.updatedModelsMap || {});

  models[args.modelKey] ??= {};
  for (const key in args.updatedModelsMap) {
    models[args.modelKey][key] = undefined;
  }

  for (const key in args.stateToUpdate.responses) {
    const response = args.stateToUpdate.responses[key];
    response.modelIds = response.modelIds?.filter(
      (key) => !deletedModelIds.includes(key)
    );

    if (Array.isArray(response.response.data)) {
      response.response.data = response.response.data.filter(
        (model) => !deletedModelIds.includes(model?._id)
      );
    }
    responses[key] = JSON.parse(JSON.stringify(response));
  }
  return {
    responses,
    models,
  };
};
