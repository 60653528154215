import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React from "react";

interface PasswordFieldsInterface {
  password: string;
  setPassword: any;
  tempPassword: string;
  setTempPassword: any;
}

const PasswordFields: React.FC<PasswordFieldsInterface> = ({
  password,
  setPassword,
  tempPassword,
  setTempPassword,
}) => {
  const [showOriginalPassword, setShowOriginalPassword] = React.useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    React.useState(false);
  return (
    <React.Fragment>
      <div className="my-3">
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span
                  className="pointer"
                  onClick={() => setShowOriginalPassword(!showOriginalPassword)}
                >
                  {showOriginalPassword ? (
                    <FontAwesomeIcon
                      icon={faEyeSlash as IconProp}
                      style={{ cursor: "pointer", color: "#2367e7" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEye as IconProp}
                      style={{ cursor: "pointer", color: "#2367e7" }}
                    />
                  )}
                </span>
              </InputAdornment>
            ),
          }}
          fullWidth
          label="Password"
          name="password"
          type={showOriginalPassword ? "text" : "password"}
          value={tempPassword ? tempPassword : ""}
          onChange={(e) => setTempPassword(e.target.value)}
        />
      </div>
      <div className="my-3">
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span
                  className="pointer"
                  onClick={() =>
                    setShowConfirmationPassword(!showConfirmationPassword)
                  }
                >
                  {showConfirmationPassword ? (
                    <FontAwesomeIcon
                      icon={faEyeSlash as IconProp}
                      style={{ cursor: "pointer", color: "#2367e7" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEye as IconProp}
                      style={{ cursor: "pointer", color: "#2367e7" }}
                    />
                  )}
                </span>
              </InputAdornment>
            ),
          }}
          fullWidth
          label="Confirm Password"
          name="password"
          type={showConfirmationPassword ? "text" : "password"}
          value={password ?? ""}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {tempPassword !== password && (
        <p className="text-danger mb-0">
          <i>Both passwords must be matching!</i>
        </p>
      )}
    </React.Fragment>
  );
};

export default PasswordFields;
