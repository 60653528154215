import initialState from "../initialState";
import { createAction, createReducer } from "@reduxjs/toolkit";
// import { START_SESSION, END_SESSION } from "../actions/session";

const startSession = createAction("START_TRADE_API_SESSION");
const endSession = createAction("END_TRADE_API_SESSION");

const session = createReducer(initialState.session, (builder) => {
  builder
    .addCase(startSession, (state, action) => {
      console.log("START_TRADE_API_SESSION");
      state.token = action.payload ? action.payload! : "";
      return state;
    })
    .addCase(endSession, (state, action) => {
      // state.token = '';
      // console.log("ENDING SESSION")
      // state = initialState.session
      return initialState.session;
    });
});

export default session;
