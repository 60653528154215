import React from "react";
import "./Modal.scss";
import Dialog from "@mui/material/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const GenericModal = (props: any) => {
  const {
    state,
    onClose,
    persist = false,
    children,
    size,
    showCloseIcon = true,
  } = props;
  const fullScreen = size === "fullscreen";
  return (
    <Dialog
      classes={{
        paper: "modal " + (size ? size : " "),
      }}
      disableEscapeKeyDown={persist}
      fullScreen={fullScreen}
      maxWidth={fullScreen ? false : "lg"}
      onClose={onClose}
      open={state}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          margin: "10px",
        }}
      >
        {showCloseIcon && (
          <FontAwesomeIcon
            icon={faTimes as IconProp}
            className="closeIcon"
            onClick={onClose}
          />
        )}
      </div>
      <div style={{ padding: "20px" }}>{children}</div>
    </Dialog>
  );
};

export default GenericModal;
