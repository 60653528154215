import Box from "@mui/material/Box";
import React from "react";
import "./RosterCard.scss";

const RosterCard = ({ item }: any) => {
  return (
    <Box
      className="rosterCard"
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <h3 className="text-center">{item.name}</h3>
    </Box>
  );
};

export default RosterCard;
