import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";
import GlobalStyles from "@mui/material/GlobalStyles";

export const StyledShoppingCartInvoice = styled(Box)`
  display: grid;
  width: 100%;
  font-size: 0.8em;

  input,
  textarea {
    font-size: 0.8em;
  }

  grid-template-columns: repeat(4, 1fr);

  [contentEditable] {
    min-width: 2em;
  }

  input:not(:is(:focus, :not(:invalid)))::-webkit-datetime-edit {
    color: transparent;
  }

  ::-webkit-input-placeholder {
    display: none !important;
  }

  .table {
    margin: 2em 0;
    margin-bottom: 2em;
    grid-column: span 4;
    width: 100%;

    td:first-child,
    th:first-child {
      width: 50%;
    }

    &:not(.borderless) {
      td,
      th {
        border: 1px solid rgba(0, 0, 0, 0.15);
      }
    }

    .MuiTableCell-paddingCheckbox {
      padding: 0 !important;
    }

    tbody .MuiOutlinedInput-root fieldset {
      display: none;
    }

    tr:not(:hover) {
      .remove-button {
        opacity: 0;
      }
    }

    *:is(td, th).delete-column {
      border: none;
      width: 0;
    }

    tfoot {
      & > tr {
        border: none;
        & > td {
          padding: 1em 0.5em;
          border: none !important;
        }
      }
    }
  }

  @media screen {
    .hide-on-screen {
      display: none;
    }
  }

  @media print {
    @page {
      size: A4;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .hide-on-print {
      display: none;
    }

    .MuiOutlinedInput-root fieldset {
      display: none;
    }

    input {
      appearance: none !important;
    }

    [type="date"]::-webkit-inner-spin-button {
      display: none;
    }
    [type="date"]::-webkit-calendar-picker-indicator {
      display: none;
    }

    .hide-on-empty:has(:is(input, textarea):invalid) {
      display: none;
      .MuiTextField-root:has(:is(input, textarea):invalid) {
        display: none;
      }
      .MuiTextField-root:has(:is(input, textarea):invalid) + label {
        display: none;
      }
    }

    .hide-row-on-empty tr:has(:is(input, textarea):invalid) {
      display: none;
    }

    tfoot {
      position: sticky;
      top: 100%;
    }
    /*         
        .MuiOutlinedInput-root fieldset {
            display: none;
        } */
  }
`;
