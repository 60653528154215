import React from "react";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import LoginCoverBackground from "../../components/PageComponents/Authentication/LoginCoverBackground";
import { forgotPassword } from "../../store/actions/session";
import "./Forgot.scss";
import ForgotForm from "../../components/PageComponents/Authentication/ForgotForm";
import { showSuccessAlert } from "../../store/actions/alerts";

const Forgot = () => {
  const [email, setEmail] = React.useState<string>("");

  const dispatch = useDispatch();
  const history = useHistory();

  const forgot = () => {
    const payload = {
      email: email,
    };

    dispatch(forgotPassword(payload, history));
    // dispatch(showSuccessAlert("Check your email to reset your password.."));
  };

  return (
    <div className="container-fluid p-0" style={{ height: "100vh" }}>
      <div className="row h-100">
        <LoginCoverBackground />

        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 login-container"
          style={{ padding: "100px" }}
        >
          <h3>Reset password</h3>
          <ForgotForm setEmail={setEmail} forgot={forgot} email={email} />
        </div>
      </div>
    </div>
  );
};

export default Forgot;
