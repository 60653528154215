import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useApiHook } from "providers/ApiProvider";
import React from "react";
import { useHistory } from "react-router-dom";
import noDataPic from "../../assets/no_data_x.gif";
import GenericHeader from "../../components/GenericHeader/GenericHeader";
import NoResourceAvailable from "../../components/NoResourceAvailable";
import Rosters from "../../components/Rosters/Rosters";
import { useShoppingCart } from "../../components/ShoppingCart/context";
import useStoreSessionSelector from "../../hooks/useStoreSessionSelector";
import { ICategory } from "../../interfaces/Category";
import { ProductsApi } from "../../services/Entities/ProductPricingService";
import { ProductPrintModal } from "../ProductPrintModal/ProductPrintModal";
import "./ProductPrices.scss";

const ProductPrices = () => {
  const history = useHistory();
  const session = useStoreSessionSelector();
  // const [categories, setCategories] = React.useState<ICategory[]>([]);
  // const [loading, setLoading] = React.useState(false);

  const products = useApiHook("Products", "getAllResources", {
    acceptsCreate: true,
  });

  const categories = useApiHook("Categories", "getAllResources", {});

  const shoppingCart = useShoppingCart();

  const [loadingPrintModal, setLoadingPrintModal] = React.useState(false);
  const [printModalOpen, setPrintModalOpen] = React.useState(false);

  // const catgoriesService = new CategoriesApi();
  const productsService = new ProductsApi();

  const navigateToCategory = (category: ICategory) => {
    history.push("/productPrices/categories/" + category._id);
  };

  const exportDataAsPDF = () => {
    setPrintModalOpen(true);
  };

  // const fetchData = () => {
  //   setLoading(true);

  //   catgoriesService
  //     .getAllResources({ token: session.token })
  //     .then((response) => setCategories(response.data.data))
  //     .finally(() => setLoading(false));
  // };

  const exportDataAsCSV = () => {
    // setLoading(true);
    productsService
      .exportCSV()
      .then(() => console.log("done downloading"))
      .catch((err) => console.log({ err }));
    // .finally(() => setLoading(false));
  };

  // console.log("rendered");
  // React.useEffect(fetchData, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        overflow: "scroll",
      }}
    >
      <GenericHeader
        customButtonsLabels={[
          "Export Data as PDF 📃",
          `Shopping Cart (${
            shoppingCart.rows?.filter((r) => !!r.product).length || 0
          }) 🛒`,
          // "Export Data as Excel"
        ]}
        customButtonsHandlers={[
          () => exportDataAsPDF(),
          () => shoppingCart.openCart(),

          // () => exportDataAsCSV(),
        ]}
      />

      {categories.loading ? (
        <CircularProgress />
      ) : categories?.data?.data.length === 0 ? (
        <NoResourceAvailable
          message="There are currently no categories"
          image={noDataPic}
        />
      ) : (
        <Rosters
          data={categories?.data?.data || []}
          exportRoster={navigateToCategory}
          className="align-self-center"
        />
      )}

      <ProductPrintModal
        state={printModalOpen}
        onClose={setPrintModalOpen}
        loading={products.loading}
        products={products?.data?.data || []}
      />
    </Box>
  );
};

export default ProductPrices;
