import useStoreSessionSelector from "hooks/useStoreSessionSelector";
import { apiContext } from "./context";
import { useContext } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ProductsApi } from "../../services/Entities/ProductPricingService";
import UserService from "../../services/Entities/UserService";
import CategoryService from "../../services/Entities/categoryService";
import SubCategoryService from "../../services/Entities/subCategoryService";
import AuthenticationService from "services/Entities/AuthenticationService";
import { AuditsService } from "services/Entities/AuditsService";

type ApiRequestCallback<Data = any, Payload = any> =
  | ((payload: Payload, ab?: AbortController) => Promise<Data>)
  | ((id: string, payload: Payload, ab?: AbortController) => Promise<Data>);

type RequestOf<O extends object> = {
  [Key in keyof O]: O[Key] extends ApiRequestCallback ? Key : never;
}[keyof O];

type ArgumentsOf<C> = C extends (...args: infer Args) => Promise<any>
  ? Args
  : never;
type ReturnOf<C> = C extends (...args: any[]) => Promise<infer R> ? R : never;

export const SKIP = Math.random().toString(16);

const services = {
  Users: new UserService(),
  Products: new ProductsApi(),
  Categories: new CategoryService(),
  Subcategories: new SubCategoryService(),
  Authentication: new AuthenticationService(),
  Audits: new AuditsService(),
} as const;

type Services = typeof services;

export default function useApiHook<
  S extends keyof Services,
  C extends RequestOf<Services[S]>,
  A extends ArgumentsOf<Services[S][C]>,
  D extends ReturnOf<Services[S][C]>
>(service: S, callback: C, ...args: A) {
  const session = useStoreSessionSelector();
  const { state, makeRequest } = useContext(apiContext);
  const argsStringified = useMemo(() => JSON.stringify(args), [args]);
  const responses = state.responses || {};
  const responseKey = [service, callback, argsStringified, session?.token].join(
    ","
  );
  const watchedResponse = responses[args?.[0]?.["watchResponse"]];
  const acceptsCreate = args?.[0]?.["acceptsCreate"];

  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const refresh = () => setRefreshTrigger(!refreshTrigger);

  useEffect(() => {
    if (loading) return;
    if (watchedResponse) {
      if (responses[responseKey]) {
        responses[responseKey].modified = true;
      }
    }
  }, [watchedResponse]);

  const Service = useMemo(() => {
    return services[service];
  }, [service]);

  useEffect(() => {
    if (loading) return;
    if (responses[responseKey]?.modified) {
      refresh();
    }
  }, [responses[responseKey]?.modified]);

  useEffect(() => {
    if (typeof Service?.[callback] !== "function") {
      return;
    }

    let ab = new AbortController();
    const _args = [...args];
    if (_args[_args.length - 1] instanceof AbortController) {
      ab = _args[_args.length - 1] as AbortController;
    } else {
      _args[_args.length] = new AbortController();
    }

    if (_args[0] === SKIP || _args?.[1]?.["skip"] === SKIP) {
      return;
    }

    if (!responses[responseKey]) setLoading(true);

    const request = () =>
      // @ts-ignore
      (Service[callback] as ApiRequestCallback)(
        // @ts-ignore
        ...(_args as any)
      ).then((r) => r.data);

    // @ts-ignore
    makeRequest({
      modelKey: service,
      responseKey: responseKey,
      request,
      acceptsCreate,
    }).finally(() => {
      setLoading(false);
    });
    return () => {
      ab.abort();
    };
  }, [responseKey, refreshTrigger, acceptsCreate]);

  return {
    loading,
    data: responses[responseKey]?.response as D,
    // data,
    refresh,
    // setData,
    responseKey,
  };
}

export function useApiHookCallback<
  S extends keyof Services,
  C extends RequestOf<Services[S]>,
  A extends ArgumentsOf<Services[S][C]>,
  D extends ReturnOf<Services[S][C]>
>(service: S, callback: C) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<D>();
  const { onModelsUpdate } = useContext(apiContext);

  const Service = useMemo(() => {
    return services[service];
  }, [service]);

  const request = useCallback(
    async (...args: A) => {
      setLoading(true);

      // @ts-ignore
      return (Service[callback] as NonNullable<ApiRequestCallback>)(
        // @ts-ignore
        ...(args as any)
      )
        .then(async (r) => {
          let action: any;
          let models: any[] = [];

          switch (callback) {
            // case 'post'
            case "postResource":
              action = "create";
              models = [
                {
                  ...r.data?.data,
                  ...(args[0] as any),
                },
              ];

              break;
            case "patchResource":
              action = "update";
              models = [
                {
                  ...r.data?.data,
                  ...(args[0] as any),
                },
              ];
              break;
            case "deleteResource":
              action = "delete";
              break;
            case "deleteManyResource":
              models = ((args[0] as any)?.data || []).map((_id: any) => ({
                _id,
              }));
              action = "delete";
              break;
          }

          await onModelsUpdate({
            action,
            modelKey: service,
            models,
          });
          return r;
        })
        .finally(() => {
          setLoading(false);
          console.log("finally");
        });
    },
    [service, callback, onModelsUpdate]
  );

  return {
    loading,
    request,
    data,
    setData,
  };
}
