import { IAudit } from "interfaces/Audit";
import { IProduct } from "interfaces/Products";
import isEqual from "lodash/isEqual";

const pricesAttributes = [
  "cash_price",
  "retail_price",
  "special_wholesale_price",
  "best_cash_price",
  "your_price",
];

export const createUpdatedProductAuditModel = (
  product: IProduct,
  changed: IProduct
): IAudit<"products", IProduct> => {
  const changes = Object.keys(changed).filter(
    (key) => !isEqual(product[key], changed[key])
  );

  const isPriceUpdated = changes.some((key) => pricesAttributes.includes(key));

  const audit: IAudit<"products", IProduct> = {
    _id: "",
    operation: "UPDATE",
    auditedCollection: "products",
    entity: product._id!,
    data: { ...product, ...changed },
    tag: isPriceUpdated ? "price-update" : "",
    created_at: new Date()?.toISOString(),
    audited_at: new Date()?.toISOString(),
  };

  return audit;
};
