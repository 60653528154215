import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faFileImage } from "@fortawesome/free-solid-svg-icons/faFileImage";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import NoResourceAvailable from "../NoResourceAvailable/NoResourceAvailable";
import "./GenericTable.scss";
import TableSortLabel from "@mui/material/TableSortLabel";

export interface GenericTableParams<T = any> {
  list: T[];
  totalCount: number;
  selectedRows: any;
  page: number;
  onSelectedChange: any;
  rowsPerPage: number;
  onRowsChange: any;
  onPageChange: any;
  numberOfData: number;
  model: string;
  passRow: any;
  image: string;
  tableConfig: any;
  loading: boolean;
  showCheckbox?: boolean;
  frontendPagination?: boolean;
  getRowProps?: (row: T, index: number) => TableRowProps;
  columnSort?: any;
  columnSortDir?: "asc" | "desc";
  onColumnSort?: (c: any) => any;
}

const GenericTable = <T = any,>(params: GenericTableParams<T>) => {
  const [selected, setSelected] = useState<any>([]);
  const [active, setActive] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = (row: any) => {
    params.passRow(row);
  };

  const renderFiles = (row: any, object: any): any => {
    let data: any = row[object.attribute];
    let max_files: number = 2;

    if (Array.isArray(data)) {
      return (
        <div>
          {data.length == 0
            ? "none"
            : data.map((file, index) =>
                index >= max_files ? (
                  ""
                ) : (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    key={file.url}
                    href={file.url}
                  >
                    <FontAwesomeIcon
                      icon={
                        file.type == "image"
                          ? (faFileImage as IconProp)
                          : (faFile as IconProp)
                      }
                      className="fontAwesomeIcon"
                    />
                  </a>
                )
              )}
          <>{data.length > max_files ? "..." : ""}</>
        </div>
      );
    } else {
      return "not a files array...";
    }
  };

  const handleSelectionChange = (rowId: any) => {
    let data = [...params.selectedRows];
    if (data.includes(rowId)) {
      data = data.filter((item) => item !== rowId);
    } else {
      data.push(rowId);
    }
    setSelected(data);
    params.onSelectedChange(data);
  };
  const selectAll = (checked: any) => {
    let dataList = [...params.selectedRows];
    if (checked) {
      params.list.forEach((item: any) => {
        if (!dataList.includes(item._id)) {
          dataList.push(item._id);
        }
      });
      dataList = [...new Set(dataList)];
      setSelected(dataList);
    } else {
      params.list.forEach((item: any) => {
        dataList = dataList.filter((listItem) => listItem !== item?._id);
      });
      setSelected(dataList);
    }
    params.onSelectedChange(dataList);
  };

  const isSelectedAll = useMemo(() => {
    if (params.selectedRows?.length === 0) return false;

    // // if the selected and the list sizes don't match, then obviously not all are selected
    // if (params.selectedRows?.length !== params.list?.length) return false;

    return params.list?.every((item) =>
      params.selectedRows.includes(item["_id"])
    );
  }, [params.selectedRows, params.list]);

  const list = useMemo(() => {
    if (params.frontendPagination) {
      return params.list?.slice(
        params.page * params.rowsPerPage,
        params.page * params.rowsPerPage + params.rowsPerPage
      );
    } else {
      return params.list || [];
    }
  }, [params.frontendPagination, params.list, params.page, params.rowsPerPage]);

  return (
    <Box
      alignItems="flex-start"
      className="usersList w-100"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {loading || params.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            color: "#2367e7",
          }}
        >
          <CircularProgress style={{ color: "#2367e7" }} />
        </div>
      ) : (
        <>
          <TableContainer component={Paper} className="tableContainer">
            <Table className="usersListTable">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {params.showCheckbox !== false && (
                      <Checkbox
                        className="checkBox"
                        color="primary"
                        onChange={(e) => selectAll(e.target.checked)}
                        checked={isSelectedAll}
                      />
                    )}
                  </TableCell>
                  {params.tableConfig.map((row: any, index: number) => (
                    <TableCell
                      key={row.attribute || index}
                      align="center"
                      sortDirection={
                        row.attribute === params.columnSort
                          ? params.columnSortDir
                          : false
                      }
                    >
                      {row.sortable && params.columnSort ? (
                        <>
                          <TableSortLabel
                            active={row.attribute === params.columnSort}
                            direction={
                              row.attribute === params.columnSort
                                ? params.columnSortDir
                                : "asc"
                            }
                            onClick={() => params.onColumnSort?.(row.attribute)}
                          >
                            {row.heading}
                          </TableSortLabel>
                        </>
                      ) : (
                        <>{row.heading}</>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {params.totalCount > 0 && (
                <TableBody>
                  {list.map((row: any, index: number) => (
                    <TableRow
                      key={row._id || index}
                      onClick={() => {
                        navigate(row);
                        setActive(row);
                      }}
                      className={active === row ? "activeRow" : ""}
                      {...params.getRowProps?.(row, index)}
                    >
                      <TableCell align="left">
                        {params.showCheckbox !== false && (
                          <Checkbox
                            className="checkBox"
                            color="primary"
                            value={row._id ? row._id : ""}
                            checked={params.selectedRows.includes(row._id)}
                            onChange={(e) => handleSelectionChange(row._id)}
                            onClick={(e) => e.stopPropagation()}
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        )}

                        {/* {console.log("rowData=====> ", row)} */}
                      </TableCell>
                      {params.tableConfig.map((object: any, index: number) => (
                        <TableCell
                          key={(row._id || index) + object.attribute}
                          className="pointer"
                          align="center"
                        >
                          {/* if column is date, display formatted date */}
                          {object.type === "date" &&
                            (dayjs(row[object.attribute]).isValid() &&
                            dayjs(row[object.attribute]).isAfter(0)
                              ? dayjs(row[object.attribute]).format(
                                  "MM/DD/YYYY"
                                )
                              : "none")}
                          {object.type === "datetime" &&
                            (dayjs(row[object.attribute]).isValid() &&
                            dayjs(row[object.attribute]).isAfter(0)
                              ? dayjs(row[object.attribute]).format(
                                  "MM/DD/YYYY hh:mm a"
                                )
                              : "--")}
                          {/*  */}
                          {object.type === "boolean" &&
                            (row[object.attribute] == true ? (
                              <FontAwesomeIcon icon={faCheck as IconProp} />
                            ) : (
                              <FontAwesomeIcon icon={faTimes as IconProp} />
                            ))}
                          {(object.type === "string" ||
                            object.type === "number") &&
                            (row[object.attribute]
                              ? row[object.attribute]
                              : "none")}
                          {object.type == "currency" &&
                            (row[object.attribute]
                              ? "€ " + row[object.attribute]
                              : "none")}
                          {object.type == "link" &&
                            (row[object.attribute] ? (
                              <a
                                href={row[object.attribute]}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <FontAwesomeIcon
                                  icon={faFilePdf as IconProp}
                                  className="fontAwesomeIcon"
                                />
                              </a>
                            ) : (
                              "none"
                            ))}
                          {object.type == "files_array" &&
                            renderFiles(row, object)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {params.totalCount === 0 && (
              <NoResourceAvailable
                resource={params.model + "s"}
                image={params.image}
                addButtonLine={true}
              />
            )}
          </TableContainer>
          {/* <h1>Number Of Data{params.numberOfData}</h1> */}
          {params.totalCount > 0 && (
            <>
              <TablePagination
                className="pagination"
                component="div"
                page={params.page}
                rowsPerPage={params.rowsPerPage}
                count={params.numberOfData || params.totalCount}
                onPageChange={params.onPageChange}
                onRowsPerPageChange={params.onRowsChange}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};
export default GenericTable;
