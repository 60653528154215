import { addMinutes, isAfter } from "date-fns";
import { ReactNode, useCallback, useState } from "react";
import { apiContext } from "./context";
import {
  ApiProviderContext,
  ApiProviderProps,
  ApiState,
  OnModelsUpdateArguments,
  OnResponseArguments,
} from "./types";
import getResponsesToUpdate from "./utils/getResponsesToUpdate";
import mergeState from "./utils/mergeState";
import onModelsUpdateHandler from "./utils/onModelsUpdateHandler";
import onResponseHandler from "./utils/onResponseHandler";

export const ApiProvider = ({ children, middleware }: ApiProviderProps) => {
  const [state, setState] = useState<ApiState>({
    models: {},
    responses: {},
  });

  const onResponse: ApiProviderContext["onResponse"] = (args) => {
    setState((s) => {
      const { models, responses } = onResponseHandler(args);

      return {
        responses: {
          ...s.responses,
          ...responses,
        },
        models: {
          ...s.models,
          [args.modelKey]: {
            ...s.models[args.modelKey],
            ...models,
          },
        },
      };
    });
  };

  const onModelsUpdate: ApiProviderContext["onModelsUpdate"] = useCallback(
    (args) => {
      const updatedModelsMap = Object.fromEntries(
        args.models?.map((model) => [model._id, model])
      );

      const responsesToUpdate = getResponsesToUpdate(state.responses, args);
      const modelsToUpdate = {
        [args.modelKey]: Object.fromEntries(
          Object.entries(state.models[args.modelKey] || {}).filter(
            ([key]) => updatedModelsMap[key]
          )
        ),
      };

      const stateToUpdate: ApiState = {
        responses: responsesToUpdate,
        models: modelsToUpdate,
      };

      const previousModels = JSON.parse(JSON.stringify(modelsToUpdate));
      const previousResponses = JSON.parse(JSON.stringify(responsesToUpdate));

      const stateUpdates = onModelsUpdateHandler({
        modelKey: args.modelKey,
        action: args.action,
        stateToUpdate,
        updatedModelsMap,
      });

      let newState = mergeState(state, stateUpdates);
      setState((state) => (newState = mergeState(state, stateUpdates)));

      const revert = () => {
        setState((state) =>
          mergeState(state, {
            models: previousModels,
            responses: previousResponses,
          })
        );
      };

      return {
        previousState: {
          models: previousModels,
          responses: previousResponses,
        },
        newState,
        revert,
      };
    },
    [state]
  );

  const makeRequest: ApiProviderContext["makeRequest"] = async ({
    modelKey,
    responseKey,
    request,
    acceptsCreate,
  }) => {
    const response = state.responses[responseKey];
    let shouldRequest = !response;

    if (
      response &&
      isAfter(Date.now(), addMinutes(new Date(response.lastFetched), 5))
    ) {
      shouldRequest = true;
    }

    if (response && response.modified) {
      shouldRequest = true;
    }

    if (shouldRequest) {
      const requestedResponse = await request();
      onResponse({
        modelKey,
        responseKey,
        response: requestedResponse,
        acceptsCreate,
      });
    }
  };

  const context: ApiProviderContext = {
    state,
    setState,
    onResponse,
    onModelsUpdate,
    makeRequest,
  };

  return (
    <>
      <apiContext.Provider
        value={{
          ...context,
          onResponse: async (args) => {
            let _args = args;
            if (middleware?.onResponse) {
              _args = await middleware.onResponse(args, context);
            }

            onResponse(_args);
          },
          onModelsUpdate: async (args) => {
            let _args = args;
            if (middleware?.onModelsUpdate) {
              _args = await middleware.onModelsUpdate(args, context);
            }

            return onModelsUpdate(_args);
          },
        }}
      >
        {children}
      </apiContext.Provider>
    </>
  );
};
